import {SET_APP_NAME, SET_APP_MODULE, SET_APP_MENU, SET_APP_CONTENT, SET_NAV_MENUS, SET_APP_LINK} from "../../constants"

const RoleAll = ["super", "admin", "client", "agent"];
const RoleSuper = ["super"];
const RoleAdmin = ["super", "admin"];
const RoleAgent = ["super", "admin", "agent"];

const getStorage = (key) => {
    let val = localStorage.getItem(key);
    if (typeof val === "undefined") {
        return "";
    }
    if (val === null) {
        return ""
    }
    return val;
}
const setStorage = (key, val) => {
    localStorage.setItem(key, val);
    console.log("localStorage > ", localStorage)
    return val;
}
const initial = {
    CurrentApp: localStorage.getItem("@pos-app") || setStorage("@pos-app", "pos"),
    CurrentModule: getStorage("@pos-module") || setStorage("@pos-module", "home"),
    CurrentMenu: getStorage("@pos-menu") || setStorage("@pos-menu", "Sale"),
    CurrentLink: getStorage("@pos-link") || setStorage("@pos-link", "sale-pop"),
    CurrentSubmenu: getStorage("@pos-submenu") || setStorage("@pos-submenu", ""),
    AllRole: RoleAll,
    menus: {
        home: {
            Sale: [
                {
                    app: "pos",
                    name: "Sale",
                    link: "sale-pop",
                    roles: RoleAgent,
                    isDefault: true
                },
            ]
        },
        "workspace": {
            Sale: [

                {
                    app: "pos",
                    name: "Point of Sale",
                    link: "sale-pop",
                    roles: RoleAgent,
                    isDefault: true
                },
            ],
            Purchase: [

                {
                    app: "pos",
                    name: "Store Purchase",
                    link: "purchase-index",
                    roles: RoleAgent,
                    isDefault: true
                },
            ],

        },
        settings: {
            Store: [
                {
                    app: "pos",
                    name: "Create Store",
                    link: "setting-store-project",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ],
            Category: [
                {
                    app: "pos",
                    name: "Purchase",
                    link: "setting-category-purchase",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pos",
                    name: "Sale",
                    link: "setting-category-sale",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ],
            Product: [
                {
                    app: "pos",
                    name: "Purchase",
                    link: "setting-product-purchase",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pos",
                    name: "Sale",
                    link: "setting-product-sale",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ],

            User: [
                {
                    app: "pos",
                    name: "Manage User",
                    link: "setting-user-create",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ],
            Allocation: [
                {
                    app: "pos",
                    name: "User To Store",
                    link: "setting-allocation-user-project",
                    roles: RoleAdmin,
                    isDefault: true
                },

            ],

        },

    },

}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SET_APP_NAME:
            newState.CurrentApp = action.payload;
            localStorage.setItem("@pos-app", action.payload);
            return Object.assign({}, newState);
        case SET_APP_MODULE:
            newState.CurrentModule = action.payload;
            localStorage.setItem("@pos-module", action.payload);
            return Object.assign({}, newState);
        case SET_APP_MENU:
            newState.CurrentMenu = action.payload;
            localStorage.setItem("@pos-menu", action.payload);
            return Object.assign({}, newState);
        case SET_APP_CONTENT:
            newState.CurrentMenu = action.payload;
            localStorage.setItem("@pos-content", action.payload);
            return Object.assign({}, newState);
        case SET_APP_LINK:
            newState.CurrentLink = action.payload;
            localStorage.setItem("@pos-link", action.payload);
            return Object.assign({}, newState);
        case SET_NAV_MENUS:
            newState.menus = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;

