import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import  {Layout, Select,Input } from "antd";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/General/PluginAddDeleteList";
import {PostToPbx} from "../../../../api/http-post";
const { TextArea } = Input;
const {Content} = Layout;
const {Option} = Select;

class SettingStoreProjectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data:[],
            Modules:[],
            Products: [],
            isLoading: false,
            component: "list",
            newEntry:{},
            selectedCompany:"",
        }
    }

    componentDidMount = async () => {

        await this.loadInitialData()
    }

    loadInitialData=async () => {
        /*await this.loadData("insurer","Insurers");
        await this.loadData("product","Products")*/
    }
    loadData=async (targetEntity,stateKey)=>{
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingProductPage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    getBooleanOption=()=>{
        let ls=[];
        ls.push({
            key:"yes",
            val:"Yes"
        });
        ls.push({
            key:"no",
            val:"No"
        })
        return ls
    }
    getCategoryOption=()=>{
        let ls=[];
        ls.push({
            key:"Project",
            val:"Project"
        });
        ls.push({
            key:"Contract",
            val:"Contract"
        })
        return ls
    }
    render() {
        const booleanOptions = this.getBooleanOption();
        const categoryOption = this.getCategoryOption();
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>SETTING STORE</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"modules"}
                            fields={[
                                {name: "name", dataType: "string", required: true, label: "Store Name", options: []},
                            ]}
                            deleteKeys={[
                                {key:"org",type:"string"},
                                {key:"name",type:"string"},
                            ]}
                            stateArray={"Data"}
                            table={"modules"}
                            addFormText={"Store"}
                            stepTitle={"Set Up new Store"}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"}
                            ]}
                            filterByCompany={true}
                            injectFields={[
                                {
                                    rowKey: "Org",
                                    stateKey: "selectedCompany",
                                    errorMsg: "Sorry you can't save, Please select your company!"
                                },
                            ]}
                        />
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(SettingStoreProjectPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

/*
<div style={{minWidth:"100%"}}>
                    <Button
                        style={{maxWidth: "10%",minWidth: "10%",float:"right"}}
                        onClick={()=>this.submitForm()}

                    >
                        Submit
                    </Button>
                </div>
 */

