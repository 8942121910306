import React, { Component } from 'react';
import { render } from 'react-dom';
import Hello from './ReceiptTitle';
import './style.css';
import {Row,Col} from "antd";

export default class PrintReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'Printer'
        };

    }
    printReceipt=async ()=> {
        window.print();
       await this.props._this.handleCheckOut();
    }

    getTableContent=()=>{
        let {state}=this.props._this;
        let totalItem = 0;
        let totalCost = 0;
        let totalTax = 0;
        let data=[];
        for (let i in state.shoppingCard) {
            let row = state.shoppingCard[i];
            data.push({
                item:row.name,
                quantity: row.qty,
                cost:parseFloat(row.cost.toFixed(2)),
                tax:row.tax,
                price:row.price,
            })
            totalItem++;
            totalCost += row.cost;
            totalTax += row.tax;
        }
        return data.map((row=>{
            return(
                <tr>
                    <td align={"left"}>{row.item}</td>
                    <td align={"center"}>{row.quantity}</td>
                    <td align={"right"}>{row.price}</td>
                    <td align={"right"}>{row.tax}</td>
                    <td align={"right"}>{row.cost}</td>
                </tr>
            )
        }))
    }
    renderTotalLine=()=>{
        let {state}=this.props._this;
        let totalItem = 0;
        let totalCost = 0;
        let totalTax = 0;
        for (let i in state.shoppingCard) {
            let row = state.shoppingCard[i];
            totalItem++;
            totalCost += row.cost;
            totalTax += row.tax;
        }

       let   data=[
           {text:"Items",tot:totalItem},
           {text:"Tax",tot:"R"+totalTax.toFixed(2)},
           {text:"Total",tot:"R"+totalCost.toFixed(2)},
       ];
        return data.map(row=>{
            return(
                <Row >
                    <Col span={2}>{row.text}</Col>
                    <Col span={2}>
                        :<span style={{float:"right"}}>{row.tot}</span>
                    </Col>
                </Row>
            )
        })
    }
    render() {
        return (
            <div className={"pReceipt"}>
                <table className="print-receipt">
                    <Hello name={this.state.name} />
                    <tr>
                        <th align={"left"}>Item</th>
                        <th align={"cebter"}>Qty</th>
                        <th align={"right"}>Price</th>
                        <th align={"right"}>Tax</th>
                        <th align={"right"}>Tot</th>
                    </tr>
                    {this.getTableContent()}
                </table>
                {this.renderTotalLine()}

                <button
                    className="hide-on-print"
                    onClick={this.printReceipt}
                    style={{marginTop:10}}
                >Print</button>
            </div>
        );
    }
}
