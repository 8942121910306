import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/General/PluginAddDeleteList";
import {PostToPbx} from "../../../../api/http-post";
import {GetFieldsAdditional} from "../../../../constants";
import {LoadDataAnyEndpoint, LoadDataNoCondition, renderCompaniesComboxbox, UpcaseFirst} from "../../../../api/fn";
import {Col, Row, Form} from "antd";
import TableDataView from "../../../../components/Common/tables/TableDataView";
import NewUserDrawer from "./NewUserDrawer";


class SettingUsersManagePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            isLoading: false,
            component: "list",
            newEntry: {},
            selectedCompany: "",
            showDrawer: false,
        }
    }

    componentDidMount = async () => {

        await this.loadInitialData()
    }
    handleChangeValKey = (val, key) => {
        console.log("handleChange ?>>>> ",val," > ",key)
        this.setState({
            [key]: val
        })
    }
    getHandleChangeValKey = (key) => {
        if (typeof this.state.newEntry[key] === "undefined") {
            return ""
        }
        return  this.state.newEntry[key];
    }
    handleChangeInput = (key, val) => {
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp);
        console.log("handleChangeInput > ", key, " > ", val);
    }
    loadInitialData = async () => {
        await LoadDataNoCondition("companies", "Companies", this);
        await this.loadUsers();

    }
    loadUsers = async () => {
        let userInfo = GetUserToken();
        let hub = {};
        // hub.Org = userInfo.Org;
        let endpoint = "/user/list";
        await LoadDataAnyEndpoint(hub, endpoint, "Data", this, (data) => {
            console.log("loadUsers > ", data)
        });

    }
    onAddRecord = () => {
        if (this.state.selectedCompany === "") {
            return alert("Please select your company!")
        }
        this.setState({
            showDrawer: true,
        })
    }
    closeDrawer = () => {
        this.setState({
            showDrawer: false,
        })
    }
    validateSubmitINput=()=>{
        let attributes=this.getAttributes();
        let newEntry = this.state.newEntry;
        for(let i in attributes){
            const row = attributes[i];
            console.log("validateSubmitINput > ",newEntry[row.fieldname],row.fieldname,attributes);
            console.log("validateSubmitINput 2  > ",newEntry);
            if(row.mandatory){
                if(typeof newEntry[row.fieldname]==="undefined"){
                    alert("Sorry "+row.fieldname+" is required field and can't be empty!");
                    return false;
                }
                if(newEntry[row.fieldname]===""){
                    alert("Sorry "+row.fieldname+" is required field and can't be empty!");
                    return false;
                }
            }
        }
        return true;
    }

    onSubmitRecord=async ()=>{
        if(!this.validateSubmitINput()){
            return
        }
        let _this=this;
        let newEntry = this.state.newEntry;
        let fullname =UpcaseFirst( newEntry.Name+" "+newEntry.Surname);
        if(!window.confirm("Are you sure to add this new user! "+fullname)){
            return
        }
        let hub={...newEntry};
        hub.Org = this.state.selectedCompany;
        hub.Fullname = fullname;
        hub.Username    = newEntry.Email;

        let endpoint = "/user/new";
        await LoadDataAnyEndpoint(hub, endpoint, "Data", this,async (data) => {
            console.log("onSubmitRecord response > ", data);
           await _this.loadUsers();
           _this.closeDrawer();
           _this.setState({
               newEntry:{},
           })
        });

        this.closeDrawer();
    }
    getAttributes=()=>{
        return [
            {fieldname:"Name",datatype:"string",options:{},mandatory:true},
            {fieldname:"Surname",datatype:"string",options:{},mandatory:true},
            {fieldname:"Phone",datatype:"string",options:{},mandatory:true},
            {fieldname:"Email",datatype:"string",options:{},mandatory:true},
            {fieldname:"Password",datatype:"string",options:{},mandatory:true},
            {fieldname:"Role",datatype:"list",options:{"agent":true,"admin":true},mandatory:true},
        ]
    }
    renderList = () => {
        let otherFunctions = [];
        let ls = [];
        let columns = [
            {id: 'FullName', label: 'Fullname', minWidth: 170, dataType: "string"},
            {id: 'Username', label: 'Username', minWidth: 170, dataType: "string"},
            {id: 'Phone', label: 'Phone', minWidth: 170, dataType: "string"},
            {id: 'Email', label: 'Email', minWidth: 170, dataType: "string"},
            {id: 'Role', label: 'Role', minWidth: 170, dataType: "string"},
        ];

        if(this.state.selectedCompany!==""){
            for (let i in this.state.Data) {
                let row = this.state.Data[i];
                if (row.Org === this.state.selectedCompany) {
                    ls.push(row);
                    console.log("users list > ",row)
                }
            }
        }



        const data = {columns: columns, data: ls};

        let titleTable = "User Records  (" + data.data.length + ") ";
        return (
            <TableDataView
                {...data}
                tableTitle={titleTable}
                onAddRecord={this.onAddRecord}
                openDetail={this.openDetail}
                otherFunctions={otherFunctions}
                options={{
                    pageSize: 20,
                }}
            />
        )
    }

    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup User</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <Row gutter={16} style={{marginTop: 20}}>
                            <Col span={24}>
                                <Form.Item
                                    label={"Select Company"}
                                >
                                    {renderCompaniesComboxbox(this.state.Companies, "customernumber", "name", this.handleChangeValKey, "selectedCompany")}
                                </Form.Item>
                            </Col>
                        </Row>

                        {this.renderList()}
                        <NewUserDrawer
                            closeDrawer={this.closeDrawer}
                            _this={this}
                            onSubmitRecord={this.onSubmitRecord}
                            attributes={this.getAttributes()}
                            visible={this.state.showDrawer}
                            handleChangeInput={this.handleChangeInput}
                        />
                    </div>
                </div>
            </Page>
        )
    }


}


export default Connector(SettingUsersManagePage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
