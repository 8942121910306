import {Form, Input, Select} from "antd";
import {LockOutlined} from "@ant-design/icons";
import React from "react";

const {Option}=Select;

export default (props)=>{
    let {_this,fields}=props;
    let {handleChange,handleChangeCombo}=_this;

    return fields.map((row) => {
        return (
            <Form.Item
                label={row.label}
                name={row.name}
                rules={[{required: row.required, message: row.message}]}
            >
                {row.type !== "option" ?
                    <Input
                        type={row.type}
                        prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}
                        onChange={(e)=>handleChange(e,row.name)}
                    /> :
                    <Select
                        name={row.name}
                        defaultValue=""
                        /*style={{ width: 120 }}*/
                        onChange={(e)=>handleChangeCombo(e,row.name)}
                    >
                        {row.options.map((record, index) => {
                            return (
                                <Option value={record.key}>{record.val}</Option>
                            )
                        })}
                    </Select>
                }
            </Form.Item>
        )
    })
}
