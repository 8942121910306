import React from "react";
import {PostToPbx} from "./http-post";
import {GetUserToken} from "./token";

export const fetchDataEntityWithConditions = async (stateEntity, moduleEntity, table, _this, Conditionals,inEndpoint) => {
    _this.setState({
        isLoading: true,
        [stateEntity]: [],
    })
    let hub = {}
    for(let i in Conditionals){

        const row = Conditionals[i];
        const key = row.key;
        const val  = row.val;
        hub[key]= val;
    }
    let endpoint = "/" + moduleEntity + "/list/" + table;
    if(typeof inEndpoint!=="undefined"){
        endpoint="/" + inEndpoint
    }
    await PostToPbx(hub, endpoint, async (data) => {
        console.log("):(--->fetchDataEntityWithConditions " + stateEntity + " -> " + moduleEntity + " -> " + table + " > response: ", data," > ");
        _this.setState({
            isLoading: false,
        })
        if (data === null) {
            return
        }
        let ls = data.RESULT
        _this.setState({
            [stateEntity]: ls,
        })
    });
}
export const fetchDataEntity = async (stateEntity, moduleEntity, table, _this, noCondition,inEndpoint) => {
    _this.setState({
        isLoading: true,
        [stateEntity]: [],
    })
    let user = GetUserToken();
    let hub = {
        Org: user.OrgCode,
        Username: user.Username,
        Role: user.Role
    }
    if (typeof noCondition !== "undefined") {
        hub = {}
    }
    /*if (user.Role !== "super") {
        hub = {
            Org: user.OrgCode,
            Username: user.Username,
            Role: user.Role
        }
    }*/
    let endpoint = "/" + moduleEntity + "/list/" + table;
    if(typeof inEndpoint!=="undefined"){
        endpoint="/" + inEndpoint
    }
    await PostToPbx(hub, endpoint, async (data) => {
        console.log("):(--->fetchData " + stateEntity + " -> " + moduleEntity + " -> " + table + " > response: ", data," > ",user);
        _this.setState({
            isLoading: false,
        })
        if (data === null) {
            //alert("Server connection Error! try again later");
            return
        }

        let ls = [];
        if (stateEntity === "Companies" && moduleEntity === "client/entity") {
            if (user.Role !== "super") {
                for (let i in data.RESULT) {
                    const row = data.RESULT[i];
                    if (row.CustomerNumber === user.OrgCode || row.CustomerNumber === user.Org ) {
                        ls.push(row)
                    }
                }
            } else {
                ls = data.RESULT
            }

        } else {
            ls = data.RESULT
        }

        _this.setState({
            isLoading: false,
            [stateEntity]: ls,
        })
    });
}
