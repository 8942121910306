import React from 'react';
import {Form, Input, Button, Checkbox} from 'antd';
import moment from "moment";
import {LockOutlined} from "@ant-design/icons"
import BackgroundImg from "../../../assets/img/background1.jpg";
import LogoImg from "../../../assets/img/logo.png";
import Connector from "../../../redux/connector";
import {PostToPbx,} from "../../../api/http-post";
import {SaveToken}from "../../../api/token";
import {fetchDataEntity} from "../../../api/entity-data";

const FormItem = Form.Item;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            username:"",
            password:"",
            Agents:[],
        }
    }
    componentDidMount=async ()=> {
        await fetchDataEntity("Agents", "/manager/entity/", "agent", this, false);
    }
    checkUsername = (rule, value, callback) => {
        const form = this.props.form;
        form.setFields({
            username: {
                value: 'asdas'
            }
        });
        // form.setFieldsValue ('pedro, manada');
    }
    handInput=(e)=>{
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]:val
        })
    }
    handleSubmit =async (e) => {
        let _this=this;
        if(e !==null){
            e.preventDefault();
        }
        console.log("handleSubmit ===> ",this.state);
        let hub={
            ...this.state
        }
        let endpoint = "/user/login";
        await PostToPbx(hub,endpoint,async (data)=>{
            console.log("):(--->Login response: ",data);
            if(data ===null){
                return
            }
            if (!data.boo) {
                alert("Login Fail please try again! "+data.msg);
                return
            }
            let orgCode=data.user.OrgCode;

            for(let i in _this.state.Agents){
                const row=_this.state.Agents[i];
                console.log("UUUUU--> ",row.Username,"===",data.user.Username);
                if(row.Username ===data.user.Username){
                    orgCode = row.Org
                }
            }
            console.log("UUUUU--> ",orgCode);
            data.user.OrgCode = orgCode;
            SaveToken(data.token,data.user);
            _this.props.SaveLoginInfo({
                Token: data.token,
                User: data.user,
                Date: moment().format("DD MMM YYYY"),
                Time: moment().format("HH:MM:s")
            });
            const nav = _this.props.nav;

            console.log("UUUUU--> ",data.user);

            console.log("LOGIN: ",nav);

            window.location.href = "#/"+nav.CurrentLink;
            window.location.reload()
        })

    }

    onFinish = async (values )=> {
        console.log('Success2:', values);
        let tmp=this.state;
        tmp.username = values.username;
        tmp.password = values.password;
        this.setState(tmp);
        await this.handleSubmit(null);
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    buildForm = () => {
        let fields = [
            {
                prefix: "user",
                type: "text",
                name: "username",
                label: "username",
                required: true,
                message: "Please input your username!"
            },
            {
                prefix: "lock",
                type: "password",
                name: "password",
                label: "password",
                required: true,
                message: "Please input your password!"
            },
        ];
        return fields.map((row) => {
            return (
                <Form.Item
                    label={row.label}
                    name={row.name}
                    rules={[{required: row.required, message: row.message}]}
                >
                    <Input
                        onChange={this.handInput}
                        type={row.type}
                        prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}
                    />
                </Form.Item>
            )
        })
    }

    render() {
        // const {getFieldDecorator} = this.props.form;
        var w = window.innerWidth;
        var h = window.innerHeight;
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    maxWidth: w,
                    /*minWidth: w,*/
                    minHeight: h,
                    maxHeight: h,
                    backgroundColor:"#a40101",

                }}>
                <div style={{
                    maxHeight: 402,
                    minHeight: 402,
                    maxWidth: 802,
                    minWidth: 802,
                    borderColor:"orange",
                    borderWidth:1,
                    borderRadius:5,
                    display: "flex",
                    border:`solid 1px orange`,
                    flex: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    padding:5
                }}>
                <img
                    src={BackgroundImg}
                    style={{
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        maxWidth: 400,
                        minWidth: 400,
                        maxHeight: 400,
                        minHeight: 400,
                        borderWidth:1,
                        borderTopLeftRadius:10,
                        borderBottomLeftRadius:10,
                    }}
                />
                <Form
                    onSubmit={this.handleSubmit}
                    className="login-form"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    name="basic"
                    initialValues={{remember: true}}
                >
                    <div style={{
                        minWidth: "100%",
                        maxHeight: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 15,
                        borderWidth:1,
                        borderTopRightRadius:10,
                        borderBottomRightRadius:10,
                    }}>
                        <img
                            style={{
                                width: 200,
                                height: 85,
                                resizeMode: "contain",

                            }}
                            src={LogoImg}
                        />
                    </div>


                    {this.buildForm()}

                    <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                        <a className="login-form-forgot" href="">Forgot password</a>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        {/*<Button type="secondary" htmlType="submit" style={{marginRight:20}}
                                onClick={()=> {
                                    window.location.href="#/register";
                                    window.location.reload()
                                }}
                        >
                            Register
                        </Button>*/}

                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                </div>
            </div>
        );
    }
}


export default Connector(LoginPage)
