const TOKEN_KEY = "token";
const TOKEN_USER = "@pbx-user";

export const GetLoginToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}
export const  SaveToken = (token, user) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_USER, JSON.stringify(user))
}
export const GetUserToken = () => {
    let user={
        Org:"",
        OrgCode:"",
        Username:"",
        Role:""
    }
    if(JSON.parse(localStorage.getItem(TOKEN_USER))!==null){
        user = JSON.parse(localStorage.getItem(TOKEN_USER));
    }
    return user
}
