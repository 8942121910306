import {GetUserToken} from "./token";
import Configures from "../configures";
import {Select} from "antd";
import React from "react";
import {PostToPbx} from "./http-post";
import moment from "moment";

const server = Configures.ServerPbx;
const {Option} = Select;

export const SubmitLogInformation=async (org,project,action,category,name,ref,data,_this)=>{
    let workDate = moment().format("YYYY-MM-DD");
    let userInfo = GetUserToken();
    let username  = userInfo.Username;

    let hub={org,project,action,category,name,ref,data,workDate,username};
    let post = {}
    post.AutoGenerate = false;
    post.HasUniqueKey = false;
    post.AutoGenerateField = "";
    post.Data = hub;
    post.Params = {}
    let endpoint = "/common/entity/Informations/insert"; // "/manager/entity/insert/extensions";

    console.log("SubmitLogInformation send > ", post);

    await LoadDataAnyEndpoint(post, endpoint, "Data", _this,async (data) => {
        console.log("SubmitLogInformation response > ", data);
    });
}
export const findRecordKey2 = (fieldsList, key, data) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            let fValue = data[row.fieldname];
            name += fValue+" ";
        }
    }
    return name;
}

export const findRecordKeyField2 = (fieldsList, key) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            return row.fieldname;
        }
    }
    return name;
}
export const GetListField = (org,project,attributes,databases) => {
    let ls = [];
    let store = attributes;
    store = SortData("position", store)
    for (let i in store) {
        const row = store[i];
        if (row.org !== org) {
            continue
        }
        if (row.module !== project) {
            continue
        }

        let type = "text";
        let options = [];
        if (row.datatype === "string") {
            type = "text";
        }
        if (row.datatype === "boolean") {
            type = "radio";
            options = [true, false];
        }
        if (row.datatype === "list") {
            type = "option";
            for (let i in row.options) {
                options.push({
                    key: i,
                    val: row.options[i],
                })
            }
        }
        if (row.datatype === "link") {
            type = "option";
            for (let e in databases) {
                const record = databases[e];
                if(record.org!==org){continue}
                if(record.module!==row.linkentity){continue}
                options.push({
                    key: record.ref,
                    val:record.ref,
                })
            }
        }

        let my = {
            field: row.fieldname,
            label: row.fieldname.toLocaleUpperCase(),
            options: options,
            type: type,
            required: row.required,
            dataType: row.datatype,
            position: row.position,
        }
        ls.push(my);

    }
    ls = SortData("position",ls);
    return ls;
}
export const SortDataDesc=(keyTosort,data)=>{
    data = SortData(keyTosort,data);
    let ls=[];
    for(let i=data.length-1;i<=0;i--){
        let row=data[i];
        ls.push(row)
    }
    return ls;
}
export const renderCompaniesComboxbox = (storage, key, val, handleChange, stateKey) => {
    let user = GetUserToken();
    let ls = [];
    for (let i in storage) {
        const row = storage[i];
        if (user.Role === "super") {
            ls.push({
                key: row[key],
                val: row[val],
            })
        } else {
            if (row[key] === user.Org) {
                ls.push({
                    key: row[key],
                    val: row[val],
                })
            }
        }


    }
    return (
        <Select
            name={stateKey}
            defaultValue=""
            onChange={(e, ee) => handleChange(ee.value, stateKey)}
        >
            {ls.map((record, index) => {
                return (
                    <Option value={record.key}>{record.val}</Option>
                )
            })}
        </Select>
    )
}
export const LoadDataAnyEndpoint = async (hub, endpoint, stateKey, _this, feedback) => {

    //let endpoint = "/common/entity/" + table + "/list";
    _this.setState({
        isLoading: true,
    })

    await PostToPbx(hub, endpoint, async (data) => {
        console.log("LoadDataNoCondition response > ", hub, " > ", data);
        let res = [];
        if (data !== null) {
            res = data.RESULT
        }
        await _this.setState({
            [stateKey]: res,
            isLoading: false,
        });
        feedback(res);
    });
}
export const LoadDataNoCondition = async (targetEntity, stateKey, _this,params,feedback) => {

    let userInfo = GetUserToken();
    let table = targetEntity;
    let hub = {};
    hub.Org = userInfo.Org;
    let post = {}
    post.AutoGenerate = false;
    post.HasUniqueKey = false;
    post.AutoGenerateField = "";
    post.Data = {};
    post.Params = {};
    if(typeof params!=="undefined"){
        post.Params = params;
    }
    post.Entity = table;
    let endpoint = "/common/entity/" + table + "/list";
    _this.setState({
        isLoading: true,
    });

    await PostToPbx(post, endpoint, async (data) => {
        console.log("LoadDataNoCondition response > ", post, " > ", data);
        let res = [];
        if (data !== null) {
            res = data.RESULT
        }

        if(typeof feedback!=="undefined"){
            feedback(res);
        }else{
            await _this.setState({
                [stateKey]: res,
                isLoading: false,
            });
        }
    });
}

export const isValueBoolean = (variable) => {
    if (typeof variable === "boolean") {
        // variable is a boolean
        return true
    }
    return false
}

export const GetRecordDisplayName = (org, module, inData, attributes, targetField) => {
    let lsAttributes = [];
    for (let i in attributes) {
        let row = attributes[i];
        if (row.org !== org || row.module !== module) {
            continue
        }
        if (row[targetField]) {
            lsAttributes.push(row)
        }
    }
    let names = "";
    if(typeof inData.data==="undefined"){
        return names
    }
    for (let i in lsAttributes) {
        const row = lsAttributes[i];
        const fieldname = row.fieldname;
        names += inData.data[fieldname] + " "
    }
    names = names.trim();
    names = UpCaseFirst(names);
    return names
}
export const getBarCodeTypeOption = () => {
    let ls = [];
    ls.push({
        key: "bar code",
        val: "Bar Code"
    });
    ls.push({
        key: "qr code",
        val: "QR Code"
    })
    return ls
}

export const getCategoryOption = () => {
    let ls = [];
    ls.push({
        key: "Project",
        val: "Project"
    });
    ls.push({
        key: "Contract",
        val: "Contract"
    })
    return ls
}
export const getBooleanOption = () => {
    let ls = [];
    ls.push({
        key: "yes",
        val: "Yes"
    });
    ls.push({
        key: "no",
        val: "No"
    })
    return ls
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function csvJSON(csv) {
    let lines = csv.split("\n");
    let result = [];
    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {

        let obj = {};
        let currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);

    }
    return {
        headers: headers,
        data: result
    }
}

export function ToDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const RecordExtractDataField = (org, record, TypeKey, attributes) => {
    for (let i in attributes) {
        const row = attributes[i];
        if (row.Org !== org) {
            continue
        }
        if (row[TypeKey]) {
            const tField = row.FieldName;
            const val = record.Data[tField];
            return val;
        }
    }
    return ""
}
export const RenderModules = (org, modules) => {
    let ls = [];
    for (let i in modules) {
        const row = modules[i];
        if (row.Org === org) {
            ls.push({
                key: row.Name,
                val: row.Name,
            })
        }
    }
    return ls
}
export const attachImageProfile = (files, dataIn) => {

    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];

        const link = FindImages2(row, files)
        console.log("attachImageProfile --> ", row.ref, " > ", link, files)
        row.link = link;
        ls.push(row)
    }

    return ls;
}
export const buildAssociationData = (props, self, targetDisplay) => {
    const Databases = props.state.Databases;
    const files = props.state.Files;
    const StoreAssociations = props.state.Associations;
    let associations = [];
    //let self = this;

    const GetRecord = (module, ref) => {
        let names = "---";
        for (let i in Databases) {
            const row = Databases[i];
            if (row.module === module && row.ref === ref) {
                names = getDisplayNameEntity(props, module, row, targetDisplay)
            }
        }
        return names;
    }
    for (let i in StoreAssociations) {
        let row = StoreAssociations[i];
        /**
         * Get Names of participants
         */
        row.MainName = GetRecord(row.MainEntity, row.Main);
        row.SupplierName = GetRecord(row.SupplierEntity, row.Supplier);
        /**
         * Get Image profile link
         */
        row.Mainimg = FindImages(row.Main, files);
        row.Supplierimg = FindImages(row.Supplier, files);
        /**
         * save into array
         */
        associations.push(row);
    }

    return associations;

}
export const getDisplayNameEntity = (props, module, record, targetDisplay) => {
    const fieldList = getListDisplayField(props, module, targetDisplay);
    let names = "";
    for (let i in fieldList) {
        const row = fieldList[i];
        names += record.data[row.fieldname] + " "
    }
    return names
}
export const getListDisplayField = (props, module, targetDisplay) => {
    let ls = [];
    const attributes = props.state.Attributes;
    for (let i in attributes) {
        const row = attributes[i];
        if (row.module === module) {
            if (row[targetDisplay]) {
                ls.push(row)
            }
        }
    }
    ls = SortData("Position", ls);
    return ls
}
export const findRecordType = (name, fieldsList) => {
    let rows = fieldsList;
    for (let i in rows) {
        let row = rows[i];
        if (row.field === name) {
            return row.type
        }
    }
    return "text"
}
export const findRecordUniqueKey = (fieldsList, _this) => {
    //const listFields = _this.getListField();
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row.unique) {
            let fValue = _this.state[row.fieldname];
            return fValue
        }
    }
    return ""
}
export const findRecordUniqueKeyX = (fieldsList, data) => {
    //const listFields = _this.getListField();
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row.unique) {
            let fValue = data[row.fieldname];
            return fValue
        }
    }
    return ""
}

export const findRecordDisplayKey = (fieldsList, key, _this) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];

        if (row[key]) {
            let fValue = _this.state[row.fieldname];
            name = name + fValue + " ";
        }
    }
    return name;
}
export const findRecordDisplayKeyX = (fieldsList, key, data) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];

        if (row[key]) {
            let fValue = data[row.fieldname];
            name = name + fValue + " ";
        }
    }
    return name;
}

export const findRecordKey = (fieldsList, key, _this) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            let fValue = _this.state[row.fieldname];
            name += fValue+" ";
        }
    }
    return name;
}
export const findRecordKeyX = (fieldsList, key, data) => {
    let name = "";
    let ls = fieldsList;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            let fValue = data[row.fieldname];
            return fValue;
        }
    }
    return name;
}
export const findRecordKeyField = (fieldsList, key, _this) => {
    let name = "";
    let ls = _this.state.Attributes;
    for (let i in ls) {
        const row = ls[i];
        if (row[key]) {
            return row.fieldname;
        }
    }
    return name;
}

export const SortData = (sortKey, data) => {
    const keyOne = sortKey;
    if (data === null) {
        return [];
    }
    data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
    return data;
}
export const ConvertBase64IntoString = (b64) => {
    let b = new Buffer(b64, 'base64');
    let s = b.toString();
    console.log("convertBase64IntoString > ", s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}

export const ConvertStringToBase64 = (htmlBody) => {
    let b = new Buffer(htmlBody);
    let s = b.toString('base64');
    console.log("convertStringToHtml > ", s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}


export const IsFindInArray = (searchKey, searchValue, data) => {
    for (let i in data) {
        const row = data[i];
        if (typeof row[searchKey] !== "undefined") {
            if (row[searchKey] === searchValue) {
                return true
            }
        }
    }

    return false
}
export const UpcaseFirst = (inWord) => {
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}
export const FindImages2 = (rowIn, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.org !== rowIn.org) {
            continue
        }
        if (row.module !== rowIn.module) {
            continue
        }
        if (row.ref !== rowIn.ref) {
            continue
        }
        if (row.type.trim() === "profile") {
            const dropboxId = row.dropboxref;
            const endpoint = "/files/download/" + dropboxId;
            let url = server + "" + endpoint;
            return url;
        }
    }
    return "https://lh3.googleusercontent.com/proxy/JhRvqc9ggu11-VxutWzJk9JTDeHXijqLAjvIcsQwMJGBXAL642lreMWW4KjPI-KvA1f9smVix0z0K6ZzDAQNFMEFpCrTjwaTVFcdnaz3QnSWc7-ENiTWNXD2fKY-iOgyl9m9EF99DbnMeXqrjqN-Nhyf"
}

export const FindImages = (ref, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.ref === ref) {
            if (row.type.trim() === "profile") {
                const dropboxId = row.dropboxref;
                const endpoint = "/document/download/" + dropboxId;
                let url = server + "" + endpoint;
                return url;
            }
        }
    }
    return "https://lh3.googleusercontent.com/proxy/JhRvqc9ggu11-VxutWzJk9JTDeHXijqLAjvIcsQwMJGBXAL642lreMWW4KjPI-KvA1f9smVix0z0K6ZzDAQNFMEFpCrTjwaTVFcdnaz3QnSWc7-ENiTWNXD2fKY-iOgyl9m9EF99DbnMeXqrjqN-Nhyf"
}
export const extraDataFromCol = (data, cols) => {
    let out = [];
    let fields = {};
    for (let i in cols) {
        const row = cols[i];
        fields[row.id] = true;
    }
    for (let i in data) {
        const row = data[i];
        let o = {};
        for (let key in row) {
            let val = row[key];
            /*let ans = Array.isArray(val);
            if(!ans && (typeof val !== "boolean")){
                val = val.replace("<nil>","");
            }*/

            if (typeof fields[key] !== "undefined") {
                o[key] = val
            }
        }
        out.push(o)
    }
    console.log("extraDataFromCol > ", out);
    return out;
}
const AttachImageProfile = (files, dataIn) => {
    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];
        const link = FindImages(row.ref, files)
        row.link = link;
        ls.push(row)
    }
    return ls;
}

export const FindFieldFromRecord = (keyValue, keyReturn, row) => {
    if (typeof row[keyReturn] !== "undefined") {
        return row[keyReturn]
    }
    return keyValue
}
export const FindFieldFromRecordWithData = (keySearch, keyValue, keyReturn, data) => {
    console.log("ZZZZ---> ", keySearch, keyValue, keyReturn, data)
    for (let i in data) {
        const row = data[i];
        if (typeof row[keySearch] !== "undefined") {
            if (row[keySearch] === keyValue) {
                return row[keyReturn]
            }
        }
    }
    return keyValue
}
export const IsNotSuperUser = () => {
    let user = GetUserToken();
    console.log("LOGIN ROLE: ", user.Role);
    if (user.Role !== "super") {
        window.location.href = "#/access-authorize-denied";
        return false
    }
    return true
}
export const UpCaseFirst = (inWord) => {
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}
export const GetRowOptions = (key, val, data) => {
    let ls = [];
    for (let i in data) {
        const row = data[i];
        console.log("GetRowOptions > ", key, val, " > ", row)
        ls.push({
            key: row[key],
            val: row[val]
        })
    }
    return ls
}
export const GetRowOptionsCondition = (key, val, data, conditions) => {
    let ls = [];
    for (let i in data) {
        const row = data[i];
        console.log("GetRowOptions > ", key, val, " > ", row);
        let boo = true;
        for (let a in conditions) {
            let record = conditions[a];
            if (row[record.key] !== record.val) {
                boo = false;
            }
        }
        if (!boo) {
            continue
        }
        ls.push({
            key: row[key],
            val: row[val]
        })
    }
    return ls
}
