import {Layout, Menu} from "antd";
import React from "react";
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import LogoImg from "../../assets/img/logo.png";
import AgentImg from "../../assets/img/background1.jpg";
import {GetUserToken} from "../../api/token";
import {CheckDisplayMenu, GetMenuList, IsRoleIn, SetMenu} from "../../api/nav";
const {   Sider } = Layout;
const { SubMenu } = Menu;


export default (props)=>{

    const nav =props.nav;
    const CurrentApp = nav.CurrentApp;
    const CurrentModule = nav.CurrentModule;
    const CurrentMenu = nav.CurrentMenu;
    console.log("CurrentApp : ",CurrentApp);
    console.log("CurrentMenu : ",CurrentMenu);

    /*
    CurrentApp: localStorage.getItem("@pbx-app") || "pbx",
    CurrentModule: localStorage.getItem("@pbx-module") || "setting",
    CurrentMenu: localStorage.getItem("@pbx-menu") || "range",
     */
    let lsModules =[];
    for(let module in nav.menus[CurrentApp]){
        lsModules.push(module);
    }

    return(
        <Sider width={275} className="site-layout-background" style={{minHeight:"100%"}}>
            <Menu
                mode="inline"
                defaultSelectedKeys={[CurrentModule]}
                defaultOpenKeys={[CurrentMenu]}
                style={{ height: '100%', minHeight: '100%',borderRight: 0 }}
            >
                {lsModules.map((module)=>{
                    let lsMenus = GetMenuList(module,props);

                    if(!CheckDisplayMenu(CurrentApp,module,props)){
                        return null
                    }

                    return(
                        <SubMenu key={module} icon={<UserOutlined />} title={module}>
                            {lsMenus.map((menu,indexMenu)=>{
                                if(!IsRoleIn(menu.roles)){
                                    return null
                                }
                                return(
                                    <Menu.Item
                                        key={menu.name}
                                        onClick={()=>SetMenu(module,menu,props)}
                                    >
                                        {menu.name}
                                    </Menu.Item>
                                )
                            })}
                        </SubMenu>
                    )
                })}
                {/*<img src={LogoImg} style={{width:"100%"}}/>
                <img src={AgentImg} style={{width:"100%"}}/>*/}
            </Menu>
        </Sider>
    )
}
