

import React from "react";
import {Col, Drawer, Input, Row, DatePicker, Space, Table,} from "antd";
import Connector from "../../../redux/connector";
import HeaderLink from "../../../components/web/POP/HeaderLink";
import {Button, Modal} from "react-bootstrap";
import "../../../components/web/POP/my.css";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import PrintReceipt from "../../../components/General/PrintReceipt";
import moment from "moment";

const currentDate = moment().format("YYYY-MM-DD");
class TransationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:currentDate,
            endDate:currentDate,
            StoreUsers: [],
            UserCodes: [],
            showDetail: false,
            Results:null,
        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await this.loadData("StoreUser", "StoreUsers", (data) => this.setFilterStoreUsers(data));
        await this.loadData("UserCode", "UserCodes", (data) => this.setFilterStoreUsers(data));
    }
    loadData = async (targetEntity, stateKey, feedback) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", post, " > ", data);
            let res = [];
            if (data !== null) {
                res = data.RESULT
            }
            await _this.setState({
                [stateKey]: res
            });
            feedback(res);
        });
    }
    setFilterStoreUsers = (data) => {
        let ls = [];
        let user = GetUserToken();
        for (let i in data) {
            let row = data[i];
            if (user.Org !== row.org) {
                continue
            }
            ls.push(row)
        }
        this.setState({
            StoreUsers: ls
        });
        console.log("Z1=> 1 ", this.state.StoreUsers, " > ", data);
    }
    getInfoLogin = () => {
        let org = "", username = '', role = '', store = '';
        const user = GetUserToken();
        org = user.Org;
        username = user.Username;
        role = user.Role;
        for (let i in this.state.StoreUsers) {
            let row = this.state.StoreUsers[i];
            if (row.user === user.Username) {
                store = row.store;
            }
        }
        return {org, username, role, store}
    }
    submitSearch=async ()=>{
        let _this = this;
        let user = GetUserToken();
        let {store}=this.getInfoLogin();
        let hub={
            Org:user.Org,
            Username:user.Username,
            Store:store,
            StartDate:this.state.startDate,
            EndDate:this.state.endDate,
        }

        console.log("Hub submitSearch: ",hub)
        let endpoint = "/pos/list-sale";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(hub, endpoint, async (data) => {
            console.log("submitSearch response > ",hub, " > ", data);
            _this.setState({
                isLoading: true,
                Results:data.RESULT,
            });
        });
    }

    renderSearchBar=()=>{
        let _this = this;
        const onChange = (date, dateString,key)=> {
            _this.setState({
                [key]:dateString
            })
            console.log(date," > ", dateString);
        }
        const myDate=(name,label)=>{
            return(
                <Space direction="vertical" style={{maxWidth:"100%",minWidth:"100%"}}>
                    <DatePicker
                        style={{maxWidth:"100%",minWidth:"100%"}}
                        placeHolder={label}
                        onChange={(date, dateString)=>onChange(date, dateString,name)}
                    />
                </Space>
            )
        }
        return(
            <Row gutter={16}>
                <Col span={10}>
                    {myDate("startDate","Start Date")}
                </Col>
                <Col span={10}>
                    {myDate("endDate","End Date")}
                </Col>
                <Col span={4}>
                    <Button onClick={()=>_this.submitSearch()}>Search</Button>
                </Col>
            </Row>
        )
    }

    viewDetail=(transNumber)=>{

    }

    renderResult = () => {
        let state = this.state;
        if(state.Results===null){
            return null;
        }
        const columns = [
            {
                title: 'Trans Number',
                dataIndex: 'TransNumber',
                key: 'TransNumber',
                render: text => <a onClick={() => this.viewDetail(text)}>{text}</a>,
            },
            {
                title: 'Trans. Date',
                dataIndex: 'TransDate',
                key: 'TransDate',
            },
            {
                title: 'Total Items',
                dataIndex: 'Items',
                key: 'Items',
            },
            {
                title: 'Tax Amount',
                dataIndex: 'TaxAmount',
                key: 'TaxAmount',
            },
            {
                title: 'Cost',
                dataIndex: 'Amount',
                key: 'Amount',
            },
            {
                title: 'User',
                dataIndex: 'User',
                key: 'User',
            },
        ];

        let dataSource=[];
        for(let i in this.state.Results.Transactions){
            let row = this.state.Results.Transactions[i];
            if(row.TransType!=="SALE"){
                continue
            }
            dataSource.push(row);
        }

        return (
            <Table
                dataSource={dataSource}
                columns={columns}
            />
        )
    }


    render() {

        return (
            <div style={styles.container}>
                <div className="container" style={styles.container2}>
                    <HeaderLink title={"Point of Sale System"}/>

                    {this.renderSearchBar()}
                    <hr/>
                    <Row>
                        <Col span={24}>
                            {this.renderResult()}
                        </Col>
                    </Row>
                </div>
            </div>
        )

    }
}

export default Connector(TransationPage);

const styles = {
    row1: {
        marginTop: 20,
    },
    inputSearch: {
        minWidth: "100%",
        maxWidth: "100%",
        marginLeft: 10,
        marginRight: 10
    },
    inputScan: {
        minWidth: "100%",
        maxWidth: "100%",
        borderColor: "black",
        borderWidth: 1
    },
    tr: {
        minWidth: "100%",
        maxWidth: "100%",
    },
    table: {
        minWidth: "100%",
        maxWidth: "100%",
        selfAlign: "center",
        /*backgroundColor: "blue"*/
    },
    container: {
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        maxWidth: "100%",
        /*backgroundColor: "red",*/
    },
    container2: {

        /*backgroundColor: "orange",*/
        justifyContent: "center",
        alignItems: "center",
        minWidth: "90%",
        maxWidth: "90%",
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
