import BackgroundImg from "../../../assets/img/background1.jpg";
import Alert, {Button, Checkbox, Form, Input, Table} from "antd";
import LogoImg from "../../../assets/img/logo.png";
import React from "react";
import Connector from "../../../redux/connector";
import {LockOutlined} from "@ant-design/icons";
import StepRoot from "../../../components/Common/register/StepRoot";
import FormBuilderFields from "../../../components/Common/register/FormBuilderFields";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";


let w = window.innerWidth;
let h = window.innerHeight;
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};


class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            Covers: [],
            PreferredCover: 0,
            ChooseCover: 0,
            ChoosePremium: 0,

            PreferredPayDate: "",
            FirstName: "",
            SecondName: "",
            IdentityValue: "",
            Phone: "",
            Email: "",
            BusinessName: "",
            BusinessAddress: "",
            BusinessRegistration: "",
            BusinessType: "",
            IdentityType:"",
        }
    }

    componentDidMount = async () => {
        await this.loadData("cover", "Covers");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingProductPage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }

    submitRecord = async () => {
        let _this = this;
        let {state}=_this;
        let userInfo = GetUserToken();
        let hub = {};
        hub.Org = userInfo.Org;
        hub.IdentityNumber  = state.IdentityValue;
        hub.IdentityType    = state.IdentityType;
        hub.FirstName       = state.FirstName;
        hub.SecondName     =state.SecondName;
        hub.Phone          =state.Phone;
        hub.Email                =state.Email;
        hub.BusinessName         =state.BusinessName;
        hub.BusinessAddress       = state.BusinessAddress;
        hub.BusinessRegistration  = state.BusinessRegistration;
        hub.BusinessType         =state.BusinessType;
        hub.Cover      = state.ChooseCover;
        hub.Premium    =state.ChoosePremium;
        hub.StartDate  = state.PreferredPayDate;
        hub.Insurer   ="";
        hub.Product   =""

        let endpoint = "/insurance/policy/new";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(hub, endpoint, async (data) => {
            console.log("submitRecord response > ", hub, " > ", data);
            let st  = data.RESULT["state"];
            let err = data.RESULT["error"];;
            if(st!=="ok"){
                alert("Sorry error: "+err);
                return
            }
            alert("Thank you, Congrats your application have been submitted, please check your email and sms for PolicyNumber!\n" +
                "An invoice will be send to you on based on your start date");
            window.location.href="#/login";
            window.location.reload();
        });
    }
    next = () => {
        const current = this.state.current + 1;
        this.setState({current});
    }
    prev = () => {
        const current = this.state.current - 1;
        this.setState({current});
    }
    handleChange = (e, key) => {
        this.setState({
            [key]: e.target.value,
        })
    }
    handleChangeCombo = (val, key) => {
        console.log("handleChangeCombo > ", val, key)
        this.setState({
            [key]: val,
        })
    }
    handInput = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    getSteps = () => {
        return [
            {
                title: 'Name & Identity',
                content: this.renderStepName(),
            },
            {
                title: 'Contact',
                content: this.renderStepContact(),
            },
            {
                title: 'Business Info',
                content: this.renderStepBusinessInfo(),
            },
            {
                title: 'Preference',
                content: this.renderStepPreference(),
            },
            {
                title: 'Cover & Premium',
                content: this.renderStepCoverPremium(),
            },
            {
                title: 'Submit',
                content: this.renderSubmit(),
            },
        ];
    }
    renderSubmit=()=>{
        let state=this.state;
        let columns = [];
        columns.push({
            title: "",
            dataIndex: "key",
        });
        columns.push({
            title: "",
            dataIndex: "val",
        });
        let ls1=[
            { key:"Full Name", val:state.FirstName+" "+state.SecondName},
            { key:"Identity", val:state.IdentityType+" | "+state.IdentityValue},
            { key:"Contact", val:state.Phone+" | "+state.Email},
        ];
        let ls2=[
            { key:"Business Type", val:state.BusinessType},
            { key:"Business name", val:state.BusinessName},
            { key:"Business address", val:state.BusinessAddress},
        ];
        let ls3=[
            { key:"Stock Value", val:state.PreferredCover},
            { key:"Cover", val:state.ChooseCover},
            { key:"Premium", val:state.ChoosePremium},
        ];

        return(
            <div>
                <h3>Summary Info</h3>
                <div style={{
                    flex:1,
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"center",
                    alignItems:"center"
                }}>
                    <Table
                        style={{marginRight:10}}
                        columns={columns}
                        dataSource={ls1}
                        bordered
                    />
                    <Table
                        style={{marginRight:10}}
                        columns={columns}
                        dataSource={ls2}
                        bordered
                    />
                    <Table
                        style={{marginRight:10}}
                        columns={columns}
                        dataSource={ls3}
                        bordered
                    />
                </div>
            </div>
        )

    }
    renderStepName = () => {
        const fields = [
            {
                prefix: "",
                type: "text",
                name: "FirstName",
                label: "First name",
                required: true,
                message: "Please input your first name!"
            },
            {
                prefix: "",
                type: "text",
                name: "SecondName",
                label: "Second name",
                required: true,
                message: "Please input your first name!"
            },
            {
                prefix: "",
                type: "option",
                name: "IdentityType",
                label: "Identity Type",
                required: true,
                message: "Please input your first name!",
                options: [
                    {key: "ID Number", val: "ID Number"},
                    {key: "Passport", val: "Passport"},
                    {key: "Asylum Seeker", val: "Asylum Seeker"},
                ]
            },
            {
                prefix: "",
                type: "text",
                name: "IdentityValue",
                label: "Identity number",
                required: true,
                message: "Please input your Identity Value!"
            },
        ];
        return <FormBuilderFields _this={this} fields={fields}/>
    }
    renderStepContact = () => {
        const fields = [
            {
                prefix: "",
                type: "text",
                name: "Phone",
                label: "Phone Number",
                required: true,
                message: "Please input your phone number!"
            },
            {
                prefix: "user",
                type: "text",
                name: "Email",
                label: "Email Address",
                required: false,
                message: "Please input your email!"
            },
        ];

        return <FormBuilderFields _this={this} fields={fields}/>
    }
    renderStepBusinessInfo = () => {
        const fields = [
            {
                prefix: "",
                type: "option",
                name: "BusinessType",
                label: "Business Type",
                required: true,
                message: "Please input your Business Type!",
                options: [
                    {key: "Food", val: "Food"},
                    {key: "Entertainment", val: "Entertainment"},
                    {key: "Beauty", val: "Beauty"},
                    {key: "Electronics", val: "Electronics"},
                    {key: "General Retailer", val: "General Retailer"},
                ]
            },
            {
                prefix: "",
                type: "text",
                name: "BusinessName",
                label: "Business Name",
                required: true,
                message: "Please input your Business Name!"
            },
            {
                prefix: "",
                type: "text",
                name: "BusinessAddress",
                label: "Business Address",
                required: true,
                message: "Please input your Business Address!"
            },
           /* {
                prefix: "",
                type: "text",
                name: "BusinessRegistration",
                label: "Business Registration",
                required: false,
                message: "Please input your Business Registration!"
            },*/
        ];

        return <FormBuilderFields _this={this} fields={fields}/>
    }
    renderStepPreference = () => {
        const fields = [
            {
                prefix: "",
                type: "text",
                name: "PreferredCover",
                label: "Value of stock",
                required: true,
                message: "Please input your Preferred Cover!"
            },
            {
                prefix: "",
                type: "date",
                name: "PreferredPayDate",
                label: "Policy Start Date",
                required: true,
                message: "Please input your Preferred pay date!"
            },
        ];

        return <FormBuilderFields _this={this} fields={fields}/>
    }
    requestAction = (text) => {
        let arr = text.split("-");
        const cover = arr[1];
        const premium = arr[2];
        this.setState({
            ChooseCover: parseFloat(cover),
            ChoosePremium: parseFloat(premium),
        })
        console.log("requestAction > ", text)
    }
    renderStepCoverPremium = () => {
        let store = this.findCover();
        let {
            PreferredCover,
            TargetMinCover,
            TargetMaxCover,
            TargetMinPremium,
            TargetMaxPremium
        } = store;
        let columns = [];
        columns.push({
            title: "Choose",
            dataIndex: "choosePrefer",
        });
        columns.push({
            title: "Option",
            dataIndex: "option",
        });
        columns.push({
            title: "Cover",
            dataIndex: "cover",
        });
        columns.push({
            title: "Premium",
            dataIndex: "premium",
        });

        let ls = [
            {
                choosePrefer: <input name="myOption" type={"radio"}
                                     onClick={() => this.requestAction("Maximum-" + TargetMaxCover + "-" + TargetMaxPremium)}/>,
                option: "Maximum",
                cover: TargetMaxCover,
                premium: TargetMaxPremium
            }, {
                choosePrefer: <input name="myOption" type={"radio"}
                                     onClick={() => this.requestAction("Minimum-" + TargetMinCover + "-" + TargetMinPremium)}/>,
                option: "Minimum",
                cover: TargetMinCover,
                premium: TargetMinPremium
            },
        ];

        return (
            <div>
                Coming soon! PreferredCover : {this.state.PreferredCover}
                <Table
                    columns={columns}
                    dataSource={ls}
                    bordered
                />
            </div>
        )
    }
    findCover = () => {
        let state = this.state;
        let maxCover = 0, minCover = 0, maxPremium = 0, minPremium = 0;
        let amounts = [];
        let mapRecords = {};
        for (let i in state.Covers) {
            const row = state.Covers[i];
            amounts.push(row.amount);
            mapRecords[row.amount] = row;
        }

        console.log("1 Data sorting > ", amounts);
        //amounts.sort();
        amounts.sort((a, b) => a - b)
        //todo a maximum
        for (let i in amounts) {
            const amount = amounts[i];
            if (maxCover === 0) {
                if (amount >= state.PreferredCover) {
                    let rec = mapRecords[amount];
                    maxCover = amount;
                    maxPremium = rec.premium;
                }
            }
        }

        //todo a minimum

        console.log("2 Data sorting > ", amounts);
        for (let i = amounts.length; i >= 0; i--) {
            let amount = amounts[i];
            if (minCover === 0) {
                if (amount < state.PreferredCover) {
                    let rec = mapRecords[amount];
                    minCover = amount;
                    minPremium = rec.premium;
                }
            }
        }

        /*this.setState({
            TargetMinCover:minCover,
            TargetMaxCover:maxCover,
            TargetMinPremium:minPremium,
            TargetMaxPremium:maxPremium,
        });*/

        return {
            TargetMinCover: minCover,
            TargetMaxCover: maxCover,
            TargetMinPremium: minPremium,
            TargetMaxPremium: maxPremium,
        }
    }

    render() {
        // const {getFieldDecorator} = this.props.form;

        return (
            <div style={styles.container}>
                <div style={styles.subContainer2}>
                    <div>
                        <img
                            src={BackgroundImg}
                            style={styles.img}
                        />
                    </div>
                </div>
                <div style={styles.subContainer}>


                    <Form
                        onSubmit={this.handleSubmit}
                        className="login-form"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        name="basic"
                        initialValues={{remember: true}}
                        style={{
                            minWidth: "100%",
                            maxHeight: 802,
                            minHeight: 802,
                        }}
                    >
                        <div style={styles.formDiv}>
                            <img
                                style={styles.formDivImg}
                                src={LogoImg}
                            />
                        </div>

                        <StepRoot _this={this}/>

                        {/*<Form.Item {...tailLayout} name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                            <a className="login-form-forgot" href="">Forgot password</a>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="secondary" htmlType="submit" style={{marginRight: 20}}>
                                Register
                            </Button>

                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>*/}
                    </Form>
                </div>
            </div>
        );
    }
}


export default Connector(RegisterPage);

const styles = {
    Row: {
        minWidth: "100%",
    },
    formDivImg: {
        width: 85,
        height: 85,
        resizeMode: "contain",
    },
    formDiv: {
        minWidth: "100%",
        maxHeight: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15,
        borderWidth: 1,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
    img: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        maxWidth: 400,
        minWidth: 400,
        maxHeight: 400,
        minHeight: 400,
        borderWidth: 1,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    subContainer2: {
        maxHeight: 802,
        minHeight: 802,
        maxWidth: 402,
        minWidth: 402,
        borderColor: "orange",
        borderWidth: 1,
        borderRadius: 5,
        display: "flex",
        /*border: `solid 1px orange`,*/
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
        backgroundColor: "#b20002"
    },
    subContainer: {
        maxHeight: 802,
        minHeight: 802,
        maxWidth: 802,
        minWidth: 802,
        borderColor: "orange",
        borderWidth: 1,
        borderRadius: 5,
        display: "flex",
        /* border: `solid 1px orange`,*/
        flex: 1,
        justifyContent: "center",
        /*alignItems: "center",*/
        padding: 5,
    },
    container: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        maxWidth: w,
        /*minWidth: w,*/
        minHeight: h,
        maxHeight: h,
        backgroundColor: "#b20002",

    }
}
