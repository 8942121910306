import React from "react";
import {Col, Drawer, Input, Row, Table} from "antd";
import Connector from "../../../redux/connector";
import HeaderLink from "../../../components/web/POP/HeaderLink";
import {Button, Modal} from "react-bootstrap";
import "../../../components/web/POP/my.css";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import PrintReceipt from "../../../components/General/PrintReceipt";
import moment from "moment";


class SalePopPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            addItemModal: false,
            ProductList: [],
            Categories: [],
            StoreUsers: [],
            UserCodes: [],
            products: [
                /*{name: "Tomatos", code: "C1230", price: 12},
                {name: "Orange blue", code: "C1231", price: 6},
                {name: "Oignon pure sol", code: "C1232", price: 24},
                {name: "Mango", code: "C1233", price: 2},
                {name: "Banana", code: "C1234", price: 5},
                {name: "Apple red", code: "C1235", price: 4},
                {name: "Patata doux", code: "C1236", price: 18},
                {name: "Sargine oil", code: "C1237", price: 25},
                {name: "Buscuix Toys", code: "C1238", price: 68},*/
            ],
            shoppingCard: {},
            taxRate: 15,
            showPrint: false
        }
    }

    componentDidMount = async () => {
        await this.loadData("StoreUser", "StoreUsers", (data) => this.setFilterStoreUsers(data));
        await this.loadData("UserCode", "UserCodes", (data) => this.setFilterUserCodes(data));
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await this.loadData("SaleProducts", "ProductList", (data) => this.setFilterProduct(data));
        await this.loadData("SaleProductCategory", "Categories", (data) => this.setFilterCategory(data));
    }
    loadData = async (targetEntity, stateKey, feedback) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", post, " > ", data);
            let res = [];
            if (data !== null) {
                res = data.RESULT
            }
            await _this.setState({
                [stateKey]: res
            });
            feedback(res);
        });
    }
    setFilterProduct = (data) => {
        let ls = [];
        let user = GetUserToken();
        for (let i in data) {
            let row = data[i];
            if (user.Org !== row.org) {
                continue
            }
            ls.push({
                name: row.name,
                code: row.code,
                price: row.price,
                category: row.category,
                store: row.module,
                org: row.org
            })
        }
        this.setState({
            products: ls
        })
    }
    setFilterCategory = (data) => {
        let ls = [];
        let user = GetUserToken();
        for (let i in data) {
            let row = data[i];
            /*if (user.Org !== row.org) {
                continue
            }*/
            ls.push(row)
        }
        this.setState({
            Categories: ls
        })
    }
    setFilterUserCodes=(data)=>{
        let ls = [];
        let user = GetUserToken();
        for (let i in data) {
            let row = data[i];
            if (user.Org !== row.org) {
                continue
            }
            ls.push(row)
        }
        this.setState({
            UserCodes: ls
        });
        console.log("Z2=> 2 ", this.state.UserCodes, " > ", data);
    }
    setFilterStoreUsers = (data) => {
        let ls = [];
        let user = GetUserToken();
        for (let i in data) {
            let row = data[i];
            if (user.Org !== row.org) {
                continue
            }
            ls.push(row)
        }
        this.setState({
            StoreUsers: ls
        });
        console.log("Z1=> 1 ", this.state.StoreUsers, " > ", data);
    }
    getInfoLogin = () => {
        let org = "", username = '', role = '', store = '';
        const user = GetUserToken();
        org = user.Org;
        username = user.Username;
        role = user.Role;
        for (let i in this.state.StoreUsers) {
            let row = this.state.StoreUsers[i];
            if (row.user === user.Username) {
                store = row.store;
            }
        }
        return {org, username, role, store}
    }

    chooseItem = (row) => {
        let tax = this.state.taxRate * row.price / 100;
        let taxPrefix = tax.toFixed(2);
        let cost = parseFloat(taxPrefix) + row.price;
        cost = cost.toFixed(2)
        let rec = {
            code: row.code,
            name: row.name,
            price: row.price,
            quantity: 1,
            qty: 1,
            cost: parseFloat(cost),
            tax: parseFloat(taxPrefix)
        };
        let tmp = this.state;
        tmp.shoppingCard[row.code] = rec;
        this.setState(tmp);
        /*this.setState({
            ["shoppingCard"]:{[row.code]:{code:row.code,name:row.name,price:row.price,quantity:1,cost:row.price}}
        });*/
        console.log("chooseItem > ", row.code, " > ", this.state.shoppingCard);
    }

    getQuantity = (code) => {
        if (typeof this.state.shoppingCard[code] !== "undefined") {
            return this.state.shoppingCard[code];
        }
        return "0";
    }
    onChangeQuantity = (val, code) => {
        //let val = e.target.value;
        if (val === "") {
            return
        }
        let tmp = this.state;
        let rec = tmp.shoppingCard[code];
        let qty = parseInt(val);
        rec.quantity = qty;
        rec.qty = qty;
        rec.cost = qty * rec.price;
        let tax = this.state.taxRate * rec.cost / 100;
        let taxPrefix = tax.toFixed(2);
        rec.cost = rec.cost + parseFloat(taxPrefix);
        rec.tax = parseFloat(taxPrefix);
        tmp.shoppingCard[code] = rec;
        this.setState(tmp)
    }
    removeItem = (name) => {
        let code = "";
        for (let i in this.state.products) {
            let row = this.state.products[i];
            if (row.name === name) {
                code = row.code;
            }
        }
        let tmp = this.state;
        delete tmp.shoppingCard[code];
        this.setState(tmp);
    }
    getSaleLine = () => {
        let {state} = this;
        let totalItem = 0;
        let totalCost = 0;
        let totalTax = 0;
        let data = [];
        for (let i in state.shoppingCard) {
            let row = state.shoppingCard[i];
            data.push({
                code: row.code,
                item: row.name,
                quantity: row.qty,
                cost: parseFloat(row.cost.toFixed(2)),
                tax: row.tax,
                price: row.price,
            })
            totalItem++;
            totalCost += row.cost;
            totalTax += row.tax;
        }
        return {
            saleLines: data, totalItem, totalCost, totalTax
        }
    }
    handleCheckOut = async () => {
        let _this = this;
        let transNumber = this.createSaleNumber();
        let totalSale = this.getAllCost().cost;
        let date = moment().format("YYYY-MM-DD")
        let time = moment().format("HH:mm:ss")
        let user = GetUserToken();
        let {saleLines, totalItem, totalCost, totalTax} = this.getSaleLine();
        let {store} = this.getInfoLogin();
        let TransactionItems = [];
        let Transactions = {
            Org: user.Org,
            TransDate: date,
            TransType: "SALE",
            Store: store,
            TaxRate: this.state.taxRate,
            TaxAmount: totalTax,
            TransNumber: transNumber,
            Amount: totalSale,
            Items: totalItem,
            User: user.Username,
        };
        for (let i in saleLines) {
            let row = saleLines[i];
            /*
            item:row.name,
                quantity: row.qty,
                cost:parseFloat(row.cost.toFixed(2)),
                tax:row.tax,
                price:row.price,
             */
            TransactionItems.push({
                Org: user.Org,
                TransDate: date,
                TransType: "SALE",
                Store: store,
                TransNumber: transNumber,
                ItemCode: row.code,
                ItemName: row.item,
                Price: row.price,
                Quantity: row.quantity,
                TaxRate: this.state.taxRate,
                TaxAmount: row.tax,
                TotalAmount: row.cost,
                AmandAmount: row.cost,
                User: user.Username,
            })
        }

        let hub = {
            Transactions,
            TransactionItems,
        }
        console.log("Hub submit: ", hub)
        let endpoint = "/pos/new-sale";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(hub, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", hub, " > ", data);

            this.setState({
                checkOutModal: false,
                showPrint: false,
                shoppingCard: {},
                total: 0,
                addItemModal: false,
            });
            alert("Sale submitted!")
        });
    }

    createSaleNumber = () => {
        let timeArr = moment().format('x');
        return "TS" + timeArr
    }
    getAllCost = () => {
        let totalItem = 0;
        let totalCost = 0;
        let totalTax = 0;
        for (let i in this.state.shoppingCard) {
            let row = this.state.shoppingCard[i];
            totalCost += row.cost;
            totalTax += row.tax;
        }

        return {cost: totalCost, costText: totalCost.toFixed(2)};
    }
    requestShowPrint = () => {
        this.setState({
            showPrint: true,
        })
    }
    renderModal = () => {
        return (
            <Modal show={this.state.addItemModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add item(Product)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        ref="form"
                        onSubmit={this.handleSubmit}
                        className="form-horizontal"
                    >
                        <div className="form-group">
                            <label className="col-md-2 lead" htmlFor="name">
                                Name
                            </label>
                            <div className="col-md-8 input-group">
                                <input
                                    className="form-control"
                                    name="name"
                                    required
                                    onChange={this.handleName}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-md-2 lead" htmlFor="price">
                                Price
                            </label>
                            <div className="col-md-8 input-group">
                                <div className="input-group-addon">$</div>

                                <input
                                    type="number"
                                    step="any"
                                    min="0"
                                    onChange={this.handlePrice}
                                    className="form-control"
                                    name="price"
                                    required
                                />
                            </div>
                        </div>

                        <p className="text-danger">Enter price for item.</p>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleSubmit}>Add</Button>
                    <Button
                        onClick={() => this.setState({addItemModal: false})}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    renderModalCheckOut = () => {
        return (
            <div classNameName="modal-body">
                <Modal show={this.state.checkOutModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div ng-hide="transactionComplete" className="lead">
                            <h3>
                                Total:
                                <span className="text-danger">
                          {" "}
                                    {this.state.total.toFixed(2)}{" "}
                        </span>
                            </h3>

                            <form
                                className="form-horizontal"
                                name="checkoutForm"
                                onSubmit={this.handlePayment}
                            >
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">R</div>
                                        <input
                                            type="number"
                                            id="checkoutPaymentAmount"
                                            className="form-control input-lg"
                                            name="payment"
                                            onChange={event =>
                                                this.setState({
                                                    totalPayment: event.target.value
                                                })
                                            }
                                            min="0"
                                        />
                                    </div>
                                </div>

                                <p className="text-danger">Enter payment amount.</p>
                                <div className="lead"/>
                                <Button
                                    className="btn btn-primary btn-lg lead"
                                    onClick={this.handlePayment}
                                >
                                    Print Receipt
                                </Button>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.setState({checkOutModal: false})}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
    renderCheckoutButton = () => {
        return (
            <div>
                <button
                    className="btn btn-success lead"
                    id="checkoutButton"
                    onClick={this.requestShowPrint}
                >
                    <i className="glyphicon glyphicon-shopping-cart"/>
                    <br/>
                    <br/>
                    C<br/>
                    h<br/>
                    e<br/>
                    c<br/>
                    k<br/>
                    o<br/>
                    u<br/>
                    t
                </button>
            </div>
        )
    }
    renderShoppingItem = () => {
        const columns = [
            {
                title: 'Item',
                dataIndex: 'name',
                key: 'name',
                render: text => <a onClick={() => this.removeItem(text)}>{text}</a>,
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Tax',
                dataIndex: 'tax',
                key: 'tax',
            },
            {
                title: 'Total',
                dataIndex: 'cost',
                key: 'cost',
            },
        ];
        let dataSource = [];
        let lastRecord = {};
        let totalItem = 0;
        let totalCost = 0;
        let totalTax = 0;
        for (let i in this.state.shoppingCard) {
            let row = this.state.shoppingCard[i];
            console.log("render quantity> ", row.qty);
            row.quantity = (
                <Input
                    onChange={(e) => this.onChangeQuantity(e.target.value, row.code)}
                    /*value={row.qty}*/
                    defaultValue={row.qty}
                    type={"text"}
                    style={{maxWidth: 100}}
                />
            );
            row.cost = parseFloat(row.cost.toFixed(2))
            dataSource.push(row);
            totalItem++;
            totalCost += row.cost;
            totalTax += row.tax;
        }

        let styleTotal = {color: "red", fontSize: 24, fontWeight: "bold"};
        if (dataSource.length > 0) {
            dataSource.push({
                name: <span style={styleTotal}>Total:</span>,
                code: "",
                quantity: <span style={styleTotal}>{totalItem}</span>,
                price: "",
                tax: <span style={styleTotal}>R{totalTax.toFixed(2)}</span>,
                cost: <span style={styleTotal}>R{totalCost.toFixed(2)}</span>
            });
            /**
             * Checkout button
             * */
            dataSource.push({
                name: "",
                code: "",
                quantity: "",
                price: "",
                tax: "",
                cost: <Button
                    className="btn btn-success"
                    onClick={this.requestShowPrint}
                >Checkout</Button>
            });
        }


        return (
            <Table
                dataSource={dataSource}
                columns={columns}
            />
        )
    }

    renderInputScan = () => {
        return (
            <Input
                autoFucos={true}
                style={styles.inputScan}
                placeholder={"Scan your code here!"}
            />
        )
    }
    renderInputSearch = () => {
        return (
            <Input
                autoFucos={true}
                style={styles.inputSearch}
                placeholder={"Search product!"}
            />
        )
    }
    renderProductList = () => {
        let ls = [];
        let {store, org} = this.getInfoLogin();
        console.log("renderProductList > ",org," > ", store, " > ",this.state.products)
        for (let i in this.state.products) {
            let row = this.state.products[i];
            if (row.org !== org) {
                continue
            }
            if (row.store !== store) {
                continue
            }
            ls.push(row);

        }

        return (
            <Row
                style={{
                    /*justifyContent: "center",
                    alignItems: "center",*/
                    minWidth: "95%",
                    maxWidth: "95%",
                    backgroundColor: "gray",
                    margin: 5
                }}
            >
                {ls.map(row => {
                    return (
                        <Col span={7}
                             style={{
                                 margin: 5,
                                 borderColor: "blue",
                                 borderWidth: 1,
                                 cursor: "pointer",
                                 textAlign: "center",
                                 backgroundColor: "black",
                                 display: "flex",
                                 flexDirection: "row",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 minHeight: 50,
                                 maxHeight: 50,

                             }}
                             onClick={() => this.chooseItem(row)}
                        >
                            <span style={{color: "white"}}>
                                {row.name}
                            </span>
                        </Col>
                    )
                })}

            </Row>
        )
    }

    renderShowPrint = () => {
        let _this = this;
        const onClose =async () => {
            await this.handleCheckOut();
            _this.setState({
                showPrint: false
            })
        }
        let visible = this.state.showPrint;
        return (
            <Drawer
                title={""}
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{paddingBottom: 80}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Skip Print
                        </Button>
                        {/*<Button  onClick={onSubmit} type="primary">
                            Submit
                        </Button>*/}
                    </div>
                }
            >
                <PrintReceipt _this={this}/>
            </Drawer>
        )
    }

    render() {

        return (
            <div style={styles.container}>
                <div className="container" style={styles.container2}>
                    <HeaderLink title={"Point of Sale System"}/>
                    <div className="text-center">
                        <span className="lead">Total</span>
                        <br/>
                        <span className="text-success checkout-total-price">
                        R{this.getAllCost().costText}
                            <span/>
                    </span>
                    </div>

                    <Row>
                        <Col span={15}>
                            {this.renderInputScan()}
                        </Col>
                        <Col span={8}>
                            {this.renderInputSearch()}
                        </Col>
                        {/*<Col span={2} style={{
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end"
                        }}>
                            <span className="pull-right">
                                <button
                                    onClick={() => this.setState({addItemModal: true})}
                                    className="btn btn-default btn-sm"
                                >
                                  <PlusOutlined/> Add Item
                                </button>
                            </span>
                        </Col>*/}
                    </Row>
                    <Row style={styles.row1}>
                        <Col span={15}>
                            {this.renderShoppingItem()}
                        </Col>
                        <Col span={9}>
                            {this.renderProductList()}
                        </Col>

                    </Row>

                    {this.renderModal()}
                    {this.renderModalCheckOut()}
                    {this.renderCheckoutButton()}
                    {this.renderShowPrint()}
                </div>
            </div>
        )

    }
}

export default Connector(SalePopPage);

const styles = {
    row1: {
        marginTop: 20,
    },
    inputSearch: {
        minWidth: "100%",
        maxWidth: "100%",
        marginLeft: 10,
        marginRight: 10
    },
    inputScan: {
        minWidth: "100%",
        maxWidth: "100%",
        borderColor: "black",
        borderWidth: 1
    },
    tr: {
        minWidth: "100%",
        maxWidth: "100%",
    },
    table: {
        minWidth: "100%",
        maxWidth: "100%",
        selfAlign: "center",
        /*backgroundColor: "blue"*/
    },
    container: {
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        maxWidth: "100%",
        /*backgroundColor: "red",*/
    },
    container2: {

        /*backgroundColor: "orange",*/
        justifyContent: "center",
        alignItems: "center",
        minWidth: "90%",
        maxWidth: "90%",
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
