import React from "react";
import {Steps, Button, message, Alert} from 'antd';

const {Step} = Steps;

export default (props)=>{
    let {_this}=props;
    let {state,getSteps,submitRecord,next,prev}=_this;
    let {current}=state;
    let steps = getSteps();
    return (
        <div style={styles.container}>
            <div style={{minWidth: "100%", color: "red"}}>
                <Alert
                    style={{margin: '16px 0'}}
                    message={"New registration form"}
                    type={"info"}
                />
            </div>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <div style={styles.stepsContent}>{steps[current].content}</div>
            <div style={styles.stepsAction}>
                <Button
                    type="secondary"
                    onClick={() => _this.setState({
                        component: "list",
                        selectedRecord: {},
                        newEntry:{},
                        clockDate:"",
                    })}
                    style={{backgroundColor: "red", color: "white", marginRight: 5}}
                >
                    Cancel
                </Button>
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={async () => {
                        await submitRecord();
                        message.success('Processing complete!');

                    }}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </div>
    )
}


const styles = {
    stepsContent: {
        marginTop: 16,
        border: '1px dashed #e9e9e9',
        borderRadius: 2,
        backgroundColor: '#fafafa',
        minHeight: 200,
        textAlign: "center",
        paddingTop: 80
    },
    stepsAction: {
        marginTop: 24
    },
    container: {
        padding: 10,
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: 802,
        /*backgroundColor: "lightgray"*/
    }
}

