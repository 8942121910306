import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/General/PluginAddDeleteList";
import {PostToPbx} from "../../../../api/http-post";
import {LoadDataAnyEndpoint} from "../../../../api/fn";


class SettingAllocationUserProjectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data:[],
            Projects:[],
            Users:[],
            isLoading: false,
            component: "list",
            newEntry:{},
            selectedCompany: "",
            selectedProject: "",
        }
    }

    componentDidMount = async () => {

        await this.loadInitialData()
    }

    loadInitialData=async () => {
        await this.loadData("modules","Projects");
        await this.loadData("StoreUser","Data");
        await this.loadUsers();
    }
    loadUsers = async () => {
        let userInfo = GetUserToken();
        let hub = {};
        // hub.Org = userInfo.Org;
        let endpoint = "/user/list";
        await LoadDataAnyEndpoint(hub, endpoint, "Users", this, (data) => {
            console.log("loadUsers > ", data)
        });

    }
    loadData=async (targetEntity,stateKey)=>{
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT
            })
        });
    }


    getValuesStateOptions=(stateKey,innerKey,innerDisplay,orgKey)=>{
        let data = this.state[stateKey];
        let ls=[];
        for(let i in data){
            let row = data[i];
            let key = row[innerKey];
            let val = row[innerDisplay];
            console.log("getValuesStateOptions > ",row," > ",this.state.selectedCompany," > ",this.state.selectedProject);
            if(row[orgKey] !==this.state.selectedCompany){
                continue
            }
            ls.push({
                key:key,
                val:val
            });
        }
        return ls
    }

    render() {
        const projects = this.getValuesStateOptions("Projects","name","name","org");
        const users = this.getValuesStateOptions("Users","Username","FullName","Org");
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Allocate  User To Store</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"StoreUser"}
                            depending={{
                                "org":{targetKey:"CustomerNumber",displayKey:"Name",storageTable:"Companies"},
                                "user":{targetKey:"Username",displayKey:"FullName",storageTable:"Users"},
                                "project":{targetKey:"name",displayKey:"name",storageTable:"Projects"},
                            }}
                            fields={[
                                {name: "user", dataType: "option", required: true, label: "Users", options: users},
                                {name: "store", dataType: "option", required: true, label: "Store", options: projects},
                            ]}
                            deleteKeys={[
                                {key:"org",type:"string"},
                                {key:"user",type:"string"},
                            ]}
                            stateArray={"Data"}
                            table={"StoreUser"}
                            addFormText={"User To Store"}
                            stepTitle={"Set Up new Allocation User Store"}
                            displayRules={[
                                {rowKey:"org",stateKey:"selectedCompany"},
                                /*{rowKey:"module",stateKey:"selectedProject"}*/
                            ]}
                            filterByCompany={true}
                            filterByModule={false}
                            injectFields={[
                                {rowKey:"Org",stateKey:"selectedCompany",errorMsg:"Sorry you can't save, Please select your company!"},
                                /*{rowKey:"Module",stateKey:"selectedProject",errorMsg:"Sorry you can't save, Please select your Project!"}*/
                            ]}
                        />
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(SettingAllocationUserProjectPage);

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
