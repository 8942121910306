import {Breadcrumb} from "antd";
import React from "react";
import LogoImg from "../../assets/img/logo.png";
import AgentImg from "../../assets/img/background1.jpg";

export default (props)=>{

    const nav =props.nav;


    return(
        <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>{nav.CurrentApp.toUpperCase()}</Breadcrumb.Item>
            <Breadcrumb.Item>{nav.CurrentModule.toUpperCase()}</Breadcrumb.Item>
            <Breadcrumb.Item style={{backgroundColor: "gray" /*"#1890ff"*/,color:"white"}}>{nav.CurrentMenu.toUpperCase()}</Breadcrumb.Item>

            <img src={LogoImg} style={styles.img}/>
        </Breadcrumb>
    )
}

const styles={
    img:{
        minHeight:25,
        maxHeight:25,
        float:"right"
    }
}
