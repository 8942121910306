import React from 'react';
import './App.css';
import {Router, Route, Switch} from 'react-router';
import {Provider} from 'react-redux';
import NotFoundPage from "./pages/web/Common/404";
import LoginPage from "./pages/web/Common/LoginPage";
import denied from "./pages/web/Common/denied";

import store from "./redux/store";
import RegisterPage from "./pages/web/Common/RegisterPage";
import SaleIndexPage from "./pages/web/Sales/SaleIndexPage";
import SalePopPage from "./pages/web/Sales/SalePopPage";
import PurchasePage from "./pages/web/Purchases/PurchasePage";
import SettingCategoryPurchasePage from "./pages/web/Settings/Categories/SettingCategoryPurchasePage";
import SettingStoreProjectPage from "./pages/web/Settings/Store/SettingStoreProjectPage";
import SettingCategoryPosPage from "./pages/web/Settings/Categories/SettingCategoryPosPage";
import SettingProductPurchasePage from "./pages/web/Settings/Products/SettingProductPurchasePage";
import SettingProductPosPage from "./pages/web/Settings/Products/SettingProductPosPage";
import SettingAllocationUserProjectPage from "./pages/web/Settings/Allocation/SettingAllocationUserProjectPage";
import SettingUsersManagePage from "./pages/web/Settings/Users/SettingUsersManagePage";
import TransationPage from "./pages/web/Sales/TransationPage";


const createHistory = require("history").createHashHistory;
const history = createHistory();

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/register" component={RegisterPage}/>
                    <Route exact path="/" component={LoginPage}/>

                    <Route exact path="/home-sale-index" component={SaleIndexPage}/>
                    <Route exact path="/sale-pop" component={SalePopPage}/>
                    <Route exact path="/purchase-index" component={PurchasePage}/>
                    <Route exact path="/transactions" component={TransationPage}/>


                    <Route exact path="/setting-category-purchase" component={SettingCategoryPurchasePage}/>
                    <Route exact path="/setting-store-project" component={SettingStoreProjectPage}/>
                    <Route exact path="/setting-category-sale" component={SettingCategoryPosPage}/>
                    <Route exact path="/setting-product-purchase" component={SettingProductPurchasePage}/>
                    <Route exact path="/setting-product-sale" component={SettingProductPosPage}/>
                    <Route exact path="/setting-allocation-user-project" component={SettingAllocationUserProjectPage}/>
                    <Route exact path="/setting-user-create" component={SettingUsersManagePage}/>





                    <Route exact path="/access-authorize-denied" component={denied}/>
                    <Route exact path="*" component={NotFoundPage}/>
                </Switch>
            </Router>
        </Provider>
    )
}

export default App;
