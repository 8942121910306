import React from "react";
import {Table, Row, Col, Input} from "antd";
import Connector from "../../../redux/connector";
import HeaderLink from "../../../components/web/POP/HeaderLink";
import {Modal, Button} from "react-bootstrap";
import "../../../components/web/POP/my.css";
import {PlusOutlined} from "@ant-design/icons";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";


class PurchasePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            addItemModal: false,
            products: [
                /*{name: "Fer beton", code: "C1230", price: 12,unit:"Kg",category:"Fer"},
                {name: "Cuivre", code: "C1231", price: 9,unit:"Kg",category:"Minerais"},
                {name: "Cobalt", code: "C1232", price: 5.5,unit:"Kg",category:"Minerais"},
                {name: "Charbon", code: "C1233", price: 8.75,unit:"Kg",category:"Minerais"},
                {name: "Malakite", code: "C1234", price: 15.2,unit:"Kg",category:"Minerais"},
                {name: "Colton", code: "C1235", price: 85,unit:"Kg",category:"Minerais"},*/
            ],
            shoppingCard:{},
            taxRate:15,
        }
    }
    componentDidMount=async () =>{
        await this.loadInitialData();
    }
    loadInitialData = async () => {
        await this.loadData("PurchaseProducts","ProductList",(data)=>this.setFilterProduct(data));
        await this.loadData("PurchaseProductCategory","Categories",(data)=>this.setFilterCategory(data));
        await this.loadData("StoreUser","StoreUsers",(data)=>this.setFilterStoreUsers(data));


    }
    loadData = async (targetEntity, stateKey,feedback) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", post, " > ", data);
            let res=[];
            if(data!==null){
                res=data.RESULT
            }
            await _this.setState({
                [stateKey]: res
            });
            feedback(res);
        });
    }
    setFilterProduct=(data)=>{
        let ls=[];
        let user=GetUserToken();
        for(let i in data){
            let row=data[i];
            if(user.Org !==row.org){continue}
            ls.push({
                name: row.name,
                code: row.code,
                price: row.price,
                category:row.category,
                store:row.module,
                org:row.org
            })
        }
        this.setState({
            products:ls
        })
    }
    setFilterCategory=(data)=>{
        let ls=[];
        let user=GetUserToken();
        for(let i in data){
            let row=data[i];
            if(user.Org !==row.org){continue}
            ls.push(row)
        }
        this.setState({
            Categories:ls
        })
    }
    setFilterStoreUsers=(data)=>{
        let ls=[];
        let user=GetUserToken();
        for(let i in data){
            let row=data[i];
            if(user.Org !==row.org){continue}
            ls.push(row)
        }
        this.setState({
            StoreUsers:ls
        });
        console.log("Z1=> 1 ",this.state.StoreUsers," > ",data);
    }

    chooseItem=(row)=>{
        let tax = this.state.taxRate * row.price / 100;
        let taxPrefix = tax.toFixed(2);
        let cost = parseFloat(taxPrefix) + row.price;
        let rec = {code:row.code,name:row.name,unit:row.unit,price:row.price,quantity:1,qty:1,cost:cost,tax:parseFloat(taxPrefix)};
        let tmp = this.state;
        tmp.shoppingCard[row.code] = rec;
        this.setState(tmp);
        /*this.setState({
            ["shoppingCard"]:{[row.code]:{code:row.code,name:row.name,price:row.price,quantity:1,cost:row.price}}
        });*/
        console.log("chooseItem > ",row.code," > ",this.state.shoppingCard);
    }

    getQuantity=(code)=>{
        if(typeof this.state.shoppingCard[code]!=="undefined"){
            return this.state.shoppingCard[code];
        }
        return "0";
    }
    onChangeQuantity=(val,code)=>{
        //let val = e.target.value;
        if(val===""){return}
        let tmp = this.state;
        let rec = tmp.shoppingCard[code];
        let qty = parseInt(val);
        rec.quantity = qty;
        rec.qty = qty;
        rec.cost = qty * rec.price;
        let tax = this.state.taxRate * rec.cost  / 100;
        let taxPrefix = tax.toFixed(2);
        //rec.cost = rec.cost - parseFloat(taxPrefix);
        rec.tax = parseFloat(taxPrefix);
        tmp.shoppingCard[code]=rec;
        this.setState(tmp)
    }
    removeItem=(name)=>{
        let code="";
        for(let i in this.state.products){
            let row=this.state.products[i];
            if(row.name===name){
                code=row.code;
            }
        }
        let tmp = this.state;
        delete tmp.shoppingCard[code];
        this.setState(tmp);
    }
    handleCheckOut = () => {
        this.setState({checkOutModal: true});
        this.setState({total: this.getAllCost().cost});
    }
    getInfoLogin=()=>{
        let org="",username='',role='',store='';
        const user = GetUserToken();
        org=user.Org;
        username = user.Username;
        role=user.Role;
        for(let i in this.state.StoreUsers){
            let row = this.state.StoreUsers[i];
            if(row.user===user.Username){
                store = row.store;
            }
        }
        return{org,username,role,store}
    }

    renderLivePos = () => {

    }
    renderModal = () => {
        return (
            <Modal show={this.state.addItemModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Add item(Product)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        ref="form"
                        onSubmit={this.handleSubmit}
                        className="form-horizontal"
                    >
                        <div className="form-group">
                            <label className="col-md-2 lead" htmlFor="name">
                                Name
                            </label>
                            <div className="col-md-8 input-group">
                                <input
                                    className="form-control"
                                    name="name"
                                    required
                                    onChange={this.handleName}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-md-2 lead" htmlFor="price">
                                Price
                            </label>
                            <div className="col-md-8 input-group">
                                <div className="input-group-addon">$</div>

                                <input
                                    type="number"
                                    step="any"
                                    min="0"
                                    onChange={this.handlePrice}
                                    className="form-control"
                                    name="price"
                                    required
                                />
                            </div>
                        </div>

                        <p className="text-danger">Enter price for item.</p>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleSubmit}>Add</Button>
                    <Button
                        onClick={() => this.setState({addItemModal: false})}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    renderModalCheckOut=()=>{
        return(
            <div classNameName="modal-body">
                <Modal show={this.state.checkOutModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div ng-hide="transactionComplete" className="lead">
                            <h3>
                                Total:
                                <span className="text-danger">
                          {" "}
                                    {this.state.total.toFixed(2)}{" "}
                        </span>
                            </h3>

                            <form
                                className="form-horizontal"
                                name="checkoutForm"
                                onSubmit={this.handlePayment}
                            >
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-addon">R</div>
                                        <input
                                            type="number"
                                            id="checkoutPaymentAmount"
                                            className="form-control input-lg"
                                            name="payment"
                                            onChange={event =>
                                                this.setState({
                                                    totalPayment: event.target.value
                                                })
                                            }
                                            min="0"
                                        />
                                    </div>
                                </div>

                                <p className="text-danger">Enter payment amount.</p>
                                <div className="lead"/>
                                <Button
                                    className="btn btn-primary btn-lg lead"
                                    onClick={this.handlePayment}
                                >
                                    Print Receipt
                                </Button>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.setState({checkOutModal: false})}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
    renderCheckoutButton = () => {
        return (
            <div>
                <button
                    className="btn btn-success lead"
                    id="checkoutButton"
                    onClick={this.handleCheckOut}
                >
                    <i className="glyphicon glyphicon-shopping-cart"/>
                    <br/>
                    <br/>
                    C<br/>
                    h<br/>
                    e<br/>
                    c<br/>
                    k<br/>
                    o<br/>
                    u<br/>
                    t
                </button>
            </div>
        )
    }
    renderShopingTable = () => {
        return (
            <table

                className="pos table table-responsive table-striped table-hover"
                style={styles.table}
            >
                <thead>
                <tr className="titles" style={styles.tr}>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Tax</th>
                    <th>Total</th>
                    <th/>
                </tr>
                <tr>
                    <td colSpan="6" className="text-center">
                  <span className="pull-left">
                    <button
                        onClick={() => this.setState({addItemModal: true})}
                        className="btn btn-default btn-sm"
                    >
                      <i className="glyphicon glyphicon-plus"/> Add Item
                    </button>
                  </span>

                    </td>
                </tr>

                </thead>
                <tbody>{this.renderLivePos()}</tbody>
            </table>
        )
    }
    renderShoppingItem = () => {
        const columns = [
            {
                title: 'Item',
                dataIndex: 'name',
                key: 'name',
                render: text => <a onClick={() => this.removeItem( text)}>{text}</a>,
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Price',
                dataIndex: 'priceDisplay',
                key: 'price',
            },
            /*{
                title: 'Tax',
                dataIndex: 'tax',
                key: 'tax',
            },*/
            {
                title: 'Total',
                dataIndex: 'costDisplay',
                key: 'cost',
            },
        ];
        let dataSource = [];
        let lastRecord={};
        let totalItem = 0;
        let totalCost=0;
        let totalTax=0;
        const getFixedDisplay=(valueIn)=>{
            const v = parseFloat(valueIn);
            return v.toFixed(2);
        }
        for(let i in this.state.shoppingCard){
            let row=this.state.shoppingCard[i];
            console.log("render quantity> ",row.qty);
            row.quantity  = (
                <Input
                    onChange={(e)=>this.onChangeQuantity(e.target.value,row.code)}
                    value={row.qty}
                    defaultValue={row.qty}
                    type={"number"}
                    style={{maxWidth:100}}
                />
            );
            row.priceDisplay = "R"+getFixedDisplay(row.price)+" / "+row.unit;
            row.costDisplay = "R"+getFixedDisplay(row.cost);
            dataSource.push(row);
            totalItem++;
            totalCost +=row.cost;
            totalTax +=row.tax;
        }

        let styleTotal = {color:"red",fontSize:24,fontWeight:"bold"};
        if(dataSource.length>0){
            dataSource.push({
                name:<span style={styleTotal}>Total:</span>,
                code:"",
                quantity:<span style={styleTotal}>{totalItem}</span>,
                price:"",
                tax:<span style={styleTotal}>R{totalTax.toFixed(2)}</span>,
                costDisplay:<span style={styleTotal}>R{totalCost.toFixed(2)}</span>
            })
        }


        return (
            <Table
                dataSource={dataSource}
                columns={columns}
            />
        )
    }
    getAllCost=()=>{
        let totalItem = 0;
        let totalCost=0;
        let totalTax=0;
        for(let i in this.state.shoppingCard) {
            let row = this.state.shoppingCard[i];
            totalCost +=row.cost;
            totalTax +=row.tax;
        }

        return {cost:totalCost,costText: totalCost.toFixed(2)};
    }
    renderInputScan = () => {
        return (
            <Input
                autoFucos={true}
                style={styles.inputScan}
                placeholder={"Scan your code here!"}
            />
        )
    }
    renderInputSearch = () => {
        return (
            <Input
                autoFucos={true}
                style={styles.inputSearch}
                placeholder={"Search product!"}
            />
        )
    }

    renderProductList = () => {

        let ls = [];
        let {store} = this.getInfoLogin();

        for(let i in this.state.products){
            let row=this.state.products[i];
            if(row.store===store){
                ls.push(row);
            }
        }
        return (
            <Row
                style={{
                    /*justifyContent: "center",
                    alignItems: "center",*/
                    minWidth: "95%",
                    maxWidth: "95%",
                    backgroundColor: "gray",
                    margin:5
                }}
            >
                {ls.map(row => {
                    return (
                        <Col span={7}
                             style={{
                                 margin: 5,
                                 borderColor: "blue",
                                 borderWidth: 1,
                                 cursor: "pointer",
                                 textAlign: "center",
                                 backgroundColor: "black",
                                 display: "flex",
                                 flexDirection: "row",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 minHeight:50,
                                 maxHeight:50,

                             }}
                             onClick={()=>this.chooseItem(row)}
                        >
                            <span style={{color: "white"}}>
                                {row.name}
                            </span>
                        </Col>
                    )
                })}

            </Row>
        )
    }

    render() {

        return (
            <div style={styles.container}>
                <div className="container" style={styles.container2}>
                    <HeaderLink title={"Purchase System"}/>
                    <div className="text-center">
                        <span className="lead">Total</span>
                        <br/>
                        <span className="text-success checkout-total-price">
                        R{this.getAllCost().costText}
                            <span/>
                    </span>
                    </div>
                    <Row style={styles.row1}>
                        <Col span={15}>
                            {this.renderShoppingItem()}
                        </Col>
                        <Col span={9}>
                            {this.renderInputSearch()}
                            {this.renderProductList()}
                        </Col>
                    </Row>

                    {this.renderModal()}
                    {this.renderModalCheckOut()}
                    {this.renderCheckoutButton()}
                </div>
            </div>
        )

    }
}

export default Connector(PurchasePage);

const styles = {
    row1: {
        marginTop: 20,
    },
    inputSearch: {
        minWidth: "100%",
        maxWidth: "100%",
        marginLeft: 10,
        marginRight: 10
    },
    inputScan: {
        minWidth: "100%",
        maxWidth: "100%",
        borderColor: "black",
        borderWidth: 1
    },
    tr: {
        minWidth: "100%",
        maxWidth: "100%",
    },
    table: {
        minWidth: "100%",
        maxWidth: "100%",
        selfAlign: "center",
        /*backgroundColor: "blue"*/
    },
    container: {
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        maxWidth: "100%",
        /*backgroundColor: "red",*/
    },
    container2: {

        /*backgroundColor: "orange",*/
        justifyContent: "center",
        alignItems: "center",
        minWidth: "90%",
        maxWidth: "90%",
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
