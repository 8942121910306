import React from "react";
import Page from "../../../../layout/web/Page";
import Title from "../../../../components/Common/Title";
import Connector from "../../../../redux/connector";
import {GetUserToken} from "../../../../api/token";
import PluginAddDeleteList from "../../../../components/General/PluginAddDeleteList";
import {PostToPbx} from "../../../../api/http-post";
import {GetFieldsAdditional} from "../../../../constants";


class SettingProductPurchasePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Modules: [],
            Categories: [],
            isLoading: false,
            component: "list",
            newEntry: {},
            selectedCompany:"",
            selectedCategory:"",
        }
    }

    componentDidMount = async () => {

        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("SaleProductCategory","Categories");
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingSetupSitePage loadInitialData response > ", post, " > ", data);
            let res=[];
            if(data!==null){
                res=data.RESULT
            }
            await _this.setState({
                [stateKey]: res
            })
        });
    }

    /*

     */

    getCategoriesOptions=()=>{
        let categories = [];
        for(let i in this.state.Categories){
            let row=this.state.Categories[i];
            if(row.org!==this.state.selectedCompany){
                continue
            }
            if(row.module!==this.state.selectedProject){
                continue
            }
            categories.push({
                key:row.name,
                val:row.name
            })
        }
        return categories
    }

    /*
    Code string
	Name           string
	Description string
	Barcode string
	BarcodeType string
	Price float64
	Unit string
     */
    getBarCodeType=()=>{
        return[
            {key:"BarCode",val:"Bar Code"},
            {key:"QrCode",val:"QR Code"}
        ]
    }

    render() {

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Sale Product</Title>
                    <div style={{minWidth: "100%", padding: 10}}>
                        <PluginAddDeleteList
                            _this={this}
                            targetEntity={"SaleProducts"}
                            fields={[
                                {name: "code", dataType: "string", required: true, label: "Product Code", options: []},
                                {name: "name", dataType: "string", required: true, label: "Name", options: []},
                                {name: "barcodetype", dataType: "option", required: true, label: "Barcode Type", options: this.getBarCodeType()},
                                {name: "barcode", dataType: "string", required: true, label: "Barcode Number", options:[]},
                                {name: "price", dataType: "float", required: true, label: "Price", options: []},
                                {name: "unit", dataType: "string", required: true, label: "Unit of Measure", options: []},
                            ]}
                            deleteKeys={[
                                {key: "org", type: "string"},
                                {key: "module", type: "string"},
                                {key: "category", type: "string"},
                                {key: "name", type: "string"},
                            ]}
                            stateArray={"Data"}
                            table={"SaleProducts"}
                            addFormText={"Product"}
                            stepTitle={"Set Up new sale product"}
                            displayRules={[
                                {rowKey: "org", stateKey: "selectedCompany"},
                                {rowKey: "module", stateKey: "selectedProject"}
                            ]}
                            filterByCompany={true}
                            filterByModule={true}
                            injectFields={[
                                {
                                    rowKey: "Org",
                                    stateKey: "selectedCompany",
                                    errorMsg: "Sorry you can't save, Please select your company!"
                                },
                                {
                                    rowKey: "Module",
                                    stateKey: "selectedProject",
                                    errorMsg: "Sorry you can't save, Please select your Project!"
                                },
                                {
                                    rowKey: "Category",
                                    stateKey: "selectedCategory",
                                    errorMsg: "Sorry you can't save, Please select your Category!"
                                }
                            ]}
                            injectStaticValue={[
                                /*{key:"category",val:"selectedCategory"}*/
                            ]}
                            extraFilters={[
                                {
                                    recordKey:"key",
                                    recordValue:"val",
                                    stateKey:"category",
                                    filterRecordKey:"category",
                                    filterStateKey:"selectedCategory",
                                    allowBlank:true,
                                    name:"selectedCategory",
                                    label:"Category",
                                    options:this.getCategoriesOptions(),
                                    errorMsg: "Sorry you can't save, Please select your category!"
                                }
                            ]}
                        />
                    </div>
                </div>
            </Page>
        )
    }

}


export default Connector(SettingProductPurchasePage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
