import {Menu} from "antd";
import React from "react";
import { Layout} from 'antd';
import {CheckDisplayModule} from "../../api/nav";
const { Header } = Layout;

export default (props)=>{

    let ls=[];
    const nav =props.nav;
    let defaultApp =nav.CurrentApp;
    console.log("(:)-->setAppName: ",defaultApp)
    for(let i in nav.menus){
        let module = i;
        ls.push(module)
    }

    const setAppName=(appName)=>{
        //todo let find the default menu for this app
        const store = nav.menus[appName];
        console.log("(:)-->setAppName: ",store)
        let defaultMenu = "";
        let defaultModule ="";

        for(let i in store){
            defaultModule = i;
            for(let a in store[i]){
                const row = store[i][a];
                if(row.isDefault){
                    defaultMenu = row.name;
                }
            }
            break
        }
        props.SetNavAppName(appName);
        props.SetNavAppModule(defaultModule);
        props.SetNavAppMenu(defaultMenu);
    }

    return(
        <Header className="header" style={{backgroundColor:"#a40101"}}>
            <div className="logo" />
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[defaultApp]} style={{backgroundColor:"#a40101"}}>
                {ls.map((item,index)=>{
                    if(!CheckDisplayModule(item,props)){
                        return null
                    }
                    return(
                        <Menu.Item
                            key={item}
                            onClick={()=>setAppName(item)}
                            style={{color:"yellow"}}
                        >
                            {item.toUpperCase()}
                        </Menu.Item>
                    )
                })}
            </Menu>
        </Header>
    )
}
