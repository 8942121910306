import React from "react";
import {Link} from "react-router-dom";
import {GetUserToken} from "../../../api/token";


// The Header creates links that can be used to navigate
// between routes.
const goTo = (link) => {
    window.location.href = "#/" + link;
    window.location.reload();
}
const Header = ({title}) => {
    let user = GetUserToken();
    return (
        <div className="text-center">
            <h1>
                <a href="/#/">{title}</a>
            </h1>

            <ul className="nav-menu">
                <li className="lead">
                    <Link onClick={() => goTo("purchase-index")}>Purchase</Link>
                </li>
                <li className="lead">
                    <Link onClick={() => goTo("sale-pop")}>POS</Link>
                </li>
                {user.Role !== "agent" ?
                    <React.Fragment>
                        <li className="lead">
                            <Link onClick={() => goTo("transactions")}>Transactions</Link>
                        </li>
                        <li className="lead">
                            <Link onClick={() => goTo("home")}>Home</Link>
                        </li>
                    </React.Fragment> : null}
            </ul>
        </div>
    )
}

export default Header;
